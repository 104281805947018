import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleArrowRight,
  faCircleCheck,
  faCirclePlay,
} from "@fortawesome/free-solid-svg-icons";
import { ShoppingCardContext } from "../../context/cardContext";
import { WOMEN_NFT_COUNTRIES, NFT_COUNTRIES } from "../../utils/nft_countries";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";
import { useQuery, useMutation } from "@apollo/client";
import { format, formatISO } from "date-fns";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import iconFinder from "../../utils/iconFinder.js";

import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { Carousel } from "react-responsive-carousel";

// Query
import FIND_USER_DETAILS from "../../gql/query/profile/me.query";
import FIND_UPCOMING_EVENTS from "../../gql/query/base/findUpcomingEvents.query";
import CHECK_USER_SUBSCRIPTION from "../../gql/query/base/checkBaseEventSubscription.query";
import FIND_MY_KIDS from "../../gql/query/base/findKidsForParent.query";
import FIND_BASE_DETAILS from "../../gql/query/base/findBaseDetailsForBaseDashboard.query";
import FIND_ALL_LIVE_STREAMS from "../../gql/query/base/findAllStreamsByFilter.query";

// MUTATION
import PARTICIPATE_EVENT from "../../gql/mutation/base/participateEvent.mutations";
import ENROLL_KIDS_USING_SLOT from "../../gql/mutation/base/enrollKidsUsingSlot.mutations";
import INITIATE_STRIPE_SESSION from "../../gql/mutation/base/initiateStripeSession.mutations";
import INITIATE_STRIPE_BILLING_PORTAL_SESSION from "../../gql/mutation/base/initiateStripeBillingPortalSession.mutations";
import CHECK_COUPON_CODE_VALIDITY from "../../gql/mutation/nft/checkCouponCodeValidity.mutations";

import {
  LOCAL_STORAGE_KEYS,
  EVENT_PAY,
  PAYMENT_FOR,
  SUPER_BASE_COUPON_CODE_INFO,
  RANDOM_AVATAR_URLS,
  PROJECT_BSPORTS_SUPER_BASE_SINGLE_EVENT_PURCHASE,
  PROJECT_BSPORTS_SUPER_BASE_MONTHLY_SUBSCRIPTION,
  PROJECT_BSPORTS_SUPER_BASE_EVENT_SLOT_PURCHASE,
  EVENTS_TYPE,
  OTHER_VIDEO_TYPES_TO_FETCH,
  BASEMENT_SPORTS_OFFICAL_LOCATION,
} from "../../utils/constant";
import { AvatarIcon } from "../../component/common/AvatarIcons.js";
import TransparentCard from "../../component/common/TransparentCard.jsx";
import EventsList from "../../component/events/EventsList.jsx";
import SingleEventPromotion from "../../component/events/SingleEventPromotion.jsx";
import VideoCard from "../../component/common/VideoCard.jsx";
import HighlightCard from "../../component/common/HighlightCard.jsx";
import useGetRecordings from "../../gql/hooks/useGetRecordings.js";
import { extractIdFromLink } from "../../utils/extractVideoIdFromLink.js";
import useFrontDeskMode from "../../hooks/useFrontDeskMode.js";
import FrontDesk from "../FrontDeskPage.jsx";

const cardTint = "bg-green";
const secondaryTint = "bg-black/20";

const BaseHome = () => {
  const [isFrontDeskMode, setIsFrontDeskMode] = useFrontDeskMode(false);
  // #region state and logic - remove
  const navigate = useNavigate();

  const { card, setCard } = useContext(ShoppingCardContext);

  const [showForMan, setShowForMan] = useState(false);
  const [itemsTabs, setItemsTabs] = useState(2);

  const [slotDetails, setSlotDetails] = useState({
    price: 0,
    discount: 0,
    subTtile: "",
    description: "",
  });
  const [membershipDetails, setMembershipDetails] = useState({
    price: 0,
    discount: 0,
    subTtile: "",
    description: "",
  });

  // Modal STATE
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedKidIds, setSelectedKidIds] = useState([]);

  // Coupon Code State
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeCheckResponse, setCouponCodeCheckResponse] = useState("");
  const [totalDiscountInDollar, setTotalDiscountInDollar] = useState(0);

  // Slot related STATE
  const [slotAmount, setSlotAmount] = useState(1);

  // Event price state
  const [selectedEventPrice, setSelectedEventPrice] = useState(0);

  NFT_COUNTRIES.sort((a, b) =>
    a.name.charAt(0).localeCompare(b.name.charAt(0))
  );

  WOMEN_NFT_COUNTRIES.sort((a, b) =>
    a.name.charAt(0).localeCompare(b.name.charAt(0))
  );

  // Calender State
  const [dateValue, setDateValu] = useState("");

  // Query
  const {
    data: findUserDetails,
    loading: loadingFindUserDetails,
    error: errorFindUserDetails,
    refetch: refetchFindUserDetails,
  } = useQuery(FIND_USER_DETAILS, {
    variables: {
      _id: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
    },
    skip: !localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
  });
  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
    refetch: eventsRefetch,
  } = useQuery(FIND_UPCOMING_EVENTS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  const {
    data: userSubscriptionData,
    loading: userSubscriptionLoading,
    error: userSubscriptionError,
    refetch: userSubscriptionRefetch,
  } = useQuery(CHECK_USER_SUBSCRIPTION, {
    variables: {
      userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
    skip: !localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
  });
  const {
    data: findMyKids,
    loading: findMyKidsLoading,
    error: findMyKidsError,
    refetch: findMyKidsRefetch,
  } = useQuery(FIND_MY_KIDS, {
    variables: {
      parentId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
    },
    skip: !localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
  });
  const {
    data: baseData,
    loading: baseLoading,
    error: baseError,
    refetch: baseRefetch,
  } = useQuery(FIND_BASE_DETAILS, {
    variables: {
      baseId: LOCAL_STORAGE_KEYS.SUPER_BASE_ID,
      //baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
    pollInterval: 5000,
  });

  // MUTATION
  const [
    participateEvent,
    { loading: participateEventLoading, error: participateEventError },
  ] = useMutation(PARTICIPATE_EVENT);
  const [
    enrollKidsUsingSlot,
    { loading: enrollKidsUsingSlotLoading, error: enrollKidsUsingSlotError },
  ] = useMutation(ENROLL_KIDS_USING_SLOT);
  const [
    initiateStripeSessionMutation,
    {
      loading: initiateStripeSessionMutationLoading,
      error: initiateStripeSessionMutationError,
    },
  ] = useMutation(INITIATE_STRIPE_SESSION);
  const [
    initiateStripeBillingPortalSessionMutation,
    {
      loading: initiateStripeBillingPortalSessionMutationLoading,
      error: initiateStripeBillingPortalSessionMutationError,
    },
  ] = useMutation(INITIATE_STRIPE_BILLING_PORTAL_SESSION);
  const [
    checkCouponCodeValidity,
    { loading: loadingCheckCouponValidity, error: errorCheckCouponValidity },
  ] = useMutation(CHECK_COUPON_CODE_VALIDITY);

  useEffect(() => {
    function initializePage() {
      if (card.collectionType === "Male") {
        setItemsTabs(1);
        setShowForMan(true);
      }
      eventsRefetch();
      userSubscriptionRefetch();
      findMyKidsRefetch();
      baseRefetch();
    }
    initializePage();
  }, []);

  async function joinEventAsASubscriptionUser(eventId) {
    if (!selectedKidIds || selectedKidIds.length == 0) {
      toast.error("Please select a KID to join the event!");
      return;
    }
    const { data } = await participateEvent({
      variables: {
        userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
        eventId,
        kidIds: selectedKidIds,
      },
    });
    if (data.participateEvent.errorMessage == null) {
      toast.success("Successfully enrolled into this event!");
      eventsRefetch();
      setSelectedKidIds([]);
      setIsOpen(false);
      return;
    } else {
      toast.error(data.participateEvent.errorMessage);
      return;
    }
  }

  // Check CouponCode Validity
  async function checkCouponValidity(couponCode, paymentFor) {
    // Set coupon code
    setCouponCode(couponCode);
    // Call to backend for checking this coupon code
    const { data } = await checkCouponCodeValidity({
      variables: {
        couponCode,
      },
    });
    // If coupon code is correct then set discount price
    if (data.checkCouponCodeValidity.errorMessage == null) {
      setCouponCodeCheckResponse(data.checkCouponCodeValidity.successMessage);
      // If Monthly subscription
      if (paymentFor == PAYMENT_FOR.PURCHASE_FULL_MONTH_EVENT_OF_BASE) {
        setTotalDiscountInDollar(
          SUPER_BASE_COUPON_CODE_INFO.MONTHLY_SUBSCRIPTION_DISCOUNT_IN_DOLLAR
        );
      } else if (paymentFor == PAYMENT_FOR.PURCHASE_SINGLE_EVENT_OF_BASE) {
        // Else Single event purchase
        setTotalDiscountInDollar(
          SUPER_BASE_COUPON_CODE_INFO.SINGLE_EVENT_PURCHASE_DISCOUNT_IN_DOLLAR
        );
      }
    } else if (data.checkCouponCodeValidity.errorMessage) {
      setTotalDiscountInDollar(0);
      setCouponCodeCheckResponse(data.checkCouponCodeValidity.errorMessage);
    }
  }

  // Single Event Purchase
  async function initiateStripeCheckoutUi(eventId, parentUserAvailableSlot) {
    if (!selectedKidIds || selectedKidIds.length == 0) {
      toast.error("Please select a KID to join the event!");
      return;
    }
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) {
      // If slots available then enroll kids via slots
      if (parentUserAvailableSlot > 0) {
        // Else Call the Mutation to get STRIPE session URL
        const { data } = await enrollKidsUsingSlot({
          variables: {
            parentId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
            eventId,
            kidIds: selectedKidIds,
          },
        });
        if (data.enrollKidsUsingSlot.errorMessage == null) {
          baseRefetch();
          findMyKidsRefetch();
          eventsRefetch();
          // Off modal
          setIsOpen(false);
          toast.success(data.enrollKidsUsingSlot.successMessage);
        } else {
          toast.error(data.enrollKidsUsingSlot.errorMessage);
        }
      } else {
        // Else Call the Mutation to get STRIPE session URL
        const { data } = await initiateStripeSessionMutation({
          variables: {
            project: PROJECT_BSPORTS_SUPER_BASE_SINGLE_EVENT_PURCHASE,
            userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
            eventId,
            kidIds: selectedKidIds,
            couponCode,
          },
        });
        if (data.initiateStripeSession.errorMessage == null) {
          setSelectedKidIds([]);
          setIsOpen(false);
          window.location.href = data.initiateStripeSession.additionalData; // Here additionalData is the STRIPE checkout url (generated by our server)
        } else {
          toast.error(data.initiateStripeSession.errorMessage);
        }
      }
    } else {
      // As user is not logged in, so navigate the user to registration page
      navigate(`/login`);
      return;
    }
  }

  // For Monthy subscription
  async function initiateStripeCheckoutUiForMonthlySubscription() {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) {
      // Call the Mutation to get STRIPE session URL
      const { data } = await initiateStripeSessionMutation({
        variables: {
          project: PROJECT_BSPORTS_SUPER_BASE_MONTHLY_SUBSCRIPTION,
          userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
          baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
          couponCode,
        },
      });
      if (data.initiateStripeSession.errorMessage == null) {
        window.location.href = data.initiateStripeSession.additionalData; // Here additionalData is the STRIPE checkout url (generated by our server)
      } else {
        toast.error(data.initiateStripeSession.errorMessage);
      }
    } else {
      // As user is not logged in, so navigate the user to registration page
      navigate(`/login`);
      return;
    }
  }

  // For Monthy subscription
  async function initiateStripeCheckoutUiForSlotPurchase() {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) {
      // Call the Mutation to get STRIPE session URL
      const { data } = await initiateStripeSessionMutation({
        variables: {
          project: PROJECT_BSPORTS_SUPER_BASE_EVENT_SLOT_PURCHASE,
          userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
          baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
          slotAmountToPurchase: Number(slotAmount),
        },
      });
      if (data.initiateStripeSession.errorMessage == null) {
        window.location.href = data.initiateStripeSession.additionalData; // Here additionalData is the STRIPE checkout url (generated by our server)
      } else {
        toast.error(data.initiateStripeSession.errorMessage);
      }
    } else {
      // As user is not logged in, so navigate the user to registration page
      navigate(`/login`);
      return;
    }
  }

  // This function will generate a Billing Portal for management of Subscriptions
  async function initiateStripeBillingPortalForSubscriptionManagment() {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) {
      // Call the Mutation to get STRIPE session URL
      const { data } = await initiateStripeBillingPortalSessionMutation({
        variables: {
          userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
        },
      });
      if (data.initiateStripeBillingPortalSession.errorMessage == null) {
        window.location.href =
          data.initiateStripeBillingPortalSession.additionalData; // Here additionalData is the STRIPE checkout url (generated by our server)
      }
    } else {
      // As user is not logged in, so navigate the user to registration page
      navigate(`/login`);
      return;
    }
  }

  // Handle Calender Actions
  function handleClickDay(date) {
    // Today date
    const currentDate = new Date();

    // First convert the date from STRING to DATE format
    const selectedDate = new Date(date);

    // Set current Hour, Minute, Second (As DatePicker can't handle it)
    selectedDate.setHours(currentDate.getHours());
    selectedDate.setMinutes(currentDate.getMinutes());
    selectedDate.setSeconds(currentDate.getSeconds());

    let formattedDate = formatISO(selectedDate, { representation: "complete" });

    setDateValu(formattedDate);
  }

  // Modal functions
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);

    // Make coupon code info as empty for safety
    setCouponCode("");
    setCouponCodeCheckResponse("");
    setTotalDiscountInDollar(0);
  }

  useEffect(() => {
    function setDate() {
      if (baseData?.findBaseDetails?.slotDetails) {
        setSlotDetails(
          JSON.parse(JSON.stringify(baseData.findBaseDetails?.slotDetails))
        );
      }

      if (baseData?.findBaseDetails?.membershipDetails) {
        setMembershipDetails(
          JSON.parse(
            JSON.stringify(baseData.findBaseDetails?.membershipDetails)
          )
        );
      }
    }
    setDate();
    baseRefetch();
  }, [baseData]);

  if (
    eventsLoading ||
    userSubscriptionLoading ||
    findMyKidsLoading ||
    loadingFindUserDetails ||
    baseLoading
  )
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  if (
    eventsError ||
    userSubscriptionError ||
    findMyKidsError ||
    errorFindUserDetails ||
    baseError
  ) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  // Set available slot to use

  let allSlotPurchased = baseData?.findBaseDetails?.slotPurchased;
  let singleSlotPrice = baseData?.findBaseDetails?.slotDetails.price
    ? baseData?.findBaseDetails?.slotDetails?.price
    : 25;
  let parentUserAvailableSlot = 0;

  if (allSlotPurchased) {
    let parentUserSlotInfoIndex = allSlotPurchased.findIndex(
      (item) =>
        item.parentId === localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)
    );

    if (parentUserSlotInfoIndex !== -1) {
      parentUserAvailableSlot =
        allSlotPurchased[parentUserSlotInfoIndex].slotAvailable;
    }
  }

  // #endregion

  // TODO: replace with a more robust membership check
  const isMember = baseData?.findBaseDetails?.membershipDetails != null;

  // replace with css variables

  // remove start

  return (
    <>
      {isFrontDeskMode ? (
        <FrontDesk setIsFrontDesk={setIsFrontDeskMode} />
      ) : (
        <CommonBackdrop
          rootName={"Bases"}
          pageName={"Super Base"}
          Button={() => (
            <button
              className="font-bold"
              onClick={() => setIsFrontDeskMode(!isFrontDeskMode)}
            >
              Front Desk
            </button>
          )}
        >
          <>
            <div className="w-full mx-auto grid grid-cols-1 xl:grid-cols-2 gap-4 sm:gap-6 lg:gap-8">
              {/* left side*/}
              <div className="w-full min-w-[300px] sm:min-w-[350px] h-fit">
                <div className="w-full flex text-label-secondary justify-between items-center flex-row mt-3 sm:mt-5 mb-3 sm:mb-4">
                  <h1 className="text-xl font-bold">
                    Welcome {findUserDetails?.me?.name}!
                  </h1>
                </div>

                <div className="relative">
                  <BaseTitle
                    isVisible={!baseLoading}
                    title={baseData?.findBaseDetails?.name}
                  />
                  <BaseImageCarousel baseData={baseData} />
                </div>

                <div className="mt-3 sm:mt-4 space-y-3 sm:space-y-4">
                  <BaseDetails baseData={baseData} isMember={isMember} />
                  <AddMemberCard data={membershipDetails} />
                  <SingleEventPromotion />
                  <SlotManagement
                    slotDetails={slotDetails}
                    singleSlotPrice={singleSlotPrice}
                    parentUserAvailableSlot={parentUserAvailableSlot}
                    handleCheckout={initiateStripeCheckoutUiForSlotPurchase}
                  />
                </div>
              </div>

              {/* right side */}
              <div className="w-full min-w-[300px] sm:min-w-[350px] h-fit">
                <div className="w-full flex justify-between flex-wrap gap-4">
                  <EventsList
                    label
                    eventsData={eventsData?.findUpcomingEvents}
                    findMyKids={findMyKids}
                  />
                  <HappeningNow baseData={baseData} />
                </div>

                <Highlights baseData={baseData} />
                <div className="flex justify-between flex-wrap gap-4">
                  <div className="w-full md:w-1/2">{/* ❌ */}</div>
                  <div className="w-full md:w-1/2">{/* ❌ */}</div>
                </div>
              </div>
            </div>
          </>
        </CommonBackdrop>
      )}
    </>
  );
};

export default BaseHome;

function BaseImageCarousel({ baseData }) {
  return (
    <div className="rounded-lg overflow-hidden">
      {baseData?.findBaseDetails?.sliderImages && (
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          interval={5000}
          showThumbs={false}
          className="relative"
        >
          {baseData?.findBaseDetails?.sliderImages?.map((image, index) => (
            <div key={index}>
              <img
                src={image}
                alt={index}
                className="h-[300px] sm:h-[350px] md:h-[400px] object-cover"
              />
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
}

function BaseTitle({ isVisible = false, title }) {
  return (
    <div className="absolute z-20 left-4 sm:left-8 top-3 sm:top-5">
      {isVisible && (
        <>
          <div
            className="font-extrabold font-display text-3xl sm:text-4xl md:text-5xl text-label-primary drop-shadow-lg"
            style={{ fontFamily: "'Avenir Next', sans-serif" }}
          >
            <div>{title.toUpperCase()}</div>
            SUPER BASE
          </div>
        </>
      )}
    </div>
  );
}

function BaseDetails({ baseData, isMember }) {
  return (
    <TransparentCard border className="pt-2 pb-3 pl-2 sm:pl-3 pr-3 sm:pr-5">
      <div className="gap-3 sm:gap-4 flex flex-row my-3 sm:my-4 justify-between items-start font-display text-base sm:text-lg text-label-primary">
        <div className="w-fit mt-2">
          <AvatarIcon
            avatar="/cake_base_deepskyblue.png" /* {`/${baseData?.findBaseDetails?.logo}.png`} */
          />{" "}
          {/* replace with baseData?.findBaseDetails?.logo */}
        </div>
        <div className="font-semibold flex-grow">
          {/* CAPTION */}
          <div>
            <div className="flex flex-row justify-between items-center">
              <div>
                <span className="text-white">
                  {baseData?.findBaseDetails?.name}
                </span>

                {baseData?.findBaseDetails?.isSuperbase && (
                  <span>
                    {" "}
                    Super Base{" "}
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="ml-2 text-label-primary"
                    />
                  </span>
                )}
              </div>
              {/* replace with actual check */}
              {isMember && (
                <div
                  className={`w-fit whitespace-nowrap ${secondaryTint} py-1 px-4 text-xs rounded-full`}
                >
                  ALREADY MEMBER
                </div>
              )}
            </div>
            <div className="text-xs text-gray-300">
              {baseData?.findBaseDetails?.address
                ? baseData?.findBaseDetails?.address
                : BASEMENT_SPORTS_OFFICAL_LOCATION}
            </div>
          </div>

          <div className="text-xs mt-5" style={{ color: "#ccd0e0" }}>
            {baseData?.findBaseDetails?.description}
          </div>
        </div>
      </div>
    </TransparentCard>
  );
}

function AddMemberCard({ data }) {
  const navigate = useNavigate();

  return (
    <TransparentCard>
      <div className="flex justify-between items-center font-display text-label-primary text-lg">
        <div className="font-semibold">
          {/* title + button */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>Membership</div>
            {/* add member button */}
            <button
              className="bg-black/20 text-white whitespace-nowrap text-sm font-bold py-1 px-5 rounded-full"
              onClick={() => {
                if (!localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) {
                  navigate(`/login`);
                } else {
                  navigate("/add-kid");
                }
              }}
            >
              ADD MEMBER
            </button>
          </div>

          <div className="text-md font-bold text-white">{data.subTtile}</div>

          <div className="text-sm mt-5 text-white">{data.description}</div>
        </div>
      </div>
    </TransparentCard>
  );
}

function HappeningNow({ baseData }) {
  const navigate = useNavigate();

  const {
    data: findAllStreams,
    loading: findAllStreamsLoading,
    error: findAllStreamsError,
    refetch: refetchAllVideoHighlights,
  } = useQuery(FIND_ALL_LIVE_STREAMS, {
    variables: {
      baseId: LOCAL_STORAGE_KEYS.SUPER_BASE_ID,
      fetchLiveOnly: true,
    },
    pollInterval: 3000,
  });

  const isAnyLive = findAllStreams?.findAllStreamsByFilter?.length > 0;

  return (
    <div className="w-full overflow-x-auto">
      {/* title */}
      <div className="w-full text-label-secondary flex justify-between items-center flex-row mt-5">
        <h1 style={{ fontWeight: "700" }}>Happening Now</h1>

        {/* TODO: replace with navigate */}
        <Link to={`/streams`}>
          <h1 style={{ fontWeight: "700" }}>
            See All <FontAwesomeIcon icon={faCircleArrowRight} />
          </h1>
        </Link>
      </div>

      {/* videos List */}
      <div
        className={`overflow-x-auto flex ${
          isAnyLive ? "" : "justify-center"
        } items-center flex-nowrap`}
      >
        {isAnyLive ? (
          <div className="flex flex-row gap-3">
            {findAllStreams?.findAllStreamsByFilter?.map((stream, index) => (
              <VideoCard
                key={index}
                video={stream}
                createdTime={stream?.updatedAt}
                baseName={baseData?.findBaseDetails?.name}
                avatarUrls={RANDOM_AVATAR_URLS}
                fetchOtherVideos={
                  OTHER_VIDEO_TYPES_TO_FETCH.fetchSameBaseVideos
                }
                navigateTo={`/${baseData?.findBaseDetails?.slug}/stream/${stream._id}?isFullScreen=false`}
                fallbackThumbnail="https://res.cloudinary.com/dvqldxdfv/image/upload/v1682695432/Screenshot_2023-04-28_at_9.09.42_srr2ch.png"
              />
            ))}
          </div>
        ) : (
          <p>No streams are live currently.</p>
        )}
      </div>
    </div>
  );
}

function Highlights({ baseData }) {
  const navigate = useNavigate();

  const { data: allHighlights } = useGetRecordings();

  return (
    <div>
      <div>
        {/* Title */}
        <div className="w-full text-label-secondary flex justify-between items-center flex-row mt-5">
          <h1 style={{ fontWeight: "700" }}>Super Base Highlights</h1>
          <Link to="/highlights">
            <h1 style={{ fontWeight: "700" }}>
              See All <FontAwesomeIcon icon={faCircleArrowRight} />
            </h1>
          </Link>
        </div>

        {/* Videos List */}
        <div className="overflow-x-auto flex flex-nowrap">
          {allHighlights.length > 0 ? (
            allHighlights.map((video, index) => (
              <HighlightCard
                key={index}
                video={video}
                onClick={() =>
                  navigate(
                    `/video/${extractIdFromLink(video.url)}?otherVideoTypes=${
                      OTHER_VIDEO_TYPES_TO_FETCH.fetchSameBaseVideos
                    }`
                  )
                }
              />
            ))
          ) : (
            <p className="mx-auto my-5">
              Members haven't shared recorded videos yet
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

function SlotManagement({
  slotDetails,
  singleSlotPrice,
  parentUserAvailableSlot,
  handleCheckout,
}) {
  const [slotAmount, setSlotAmount] = useState(1);
  const navigate = useNavigate();

  return (
    <div>
      <div>
        <h1 style={{ fontWeight: "700" }}>Purchase Slot!</h1>
      </div>
      <div className="mt-3">
        <div className="bg-black/20 w-full flex justify-between items-center font-display text-yellow-300 text-lg rounded-lg py-4 px-4 lg:px-6 shadow-md shadow-black/30">
          <div className="font-semibold">
            {/* title + button */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>#Slot Management</div>

              {/* slot purchase number + button */}
              <div className="ml-5">
                {/* slot number to purchase */}
                <input
                  onChange={(e) => setSlotAmount(e.target.value)}
                  className="bg-black/30 border-2  border-green-300  focus:border-yellow-300 focus:outline-none rounded-lg mr-3"
                  type="number"
                  min={1}
                  value={slotAmount}
                  style={{ width: "65px", height: "30px" }}
                />
                {/* slot purchase button */}
                <button
                  className="text-sm font-bold py-1 px-5 rounded-full"
                  style={{
                    backgroundColor: "#0000008a",
                    color: "#FFFFFF",
                  }}
                  onClick={() => {
                    if (!localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) {
                      navigate(`/login`);
                    } else {
                      handleCheckout();
                    }
                  }}
                >
                  Purchase Slot
                </button>
              </div>
            </div>

            <div
              className="text-md mt-2"
              style={{ color: "#ccd0e0", fontWeight: "bold" }}
            >
              {/* ${singleSlotPrice}/Slot ($10 discount on each 4 slots) */}
              {slotDetails.subTtile}
            </div>

            <div className="text-sm mt-5" style={{ color: "#ccd0e0" }}>
              {slotDetails.description}
            </div>

            {/* Total payable amount */}
            <div className="mt-4">
              Total amount to pay: $
              {slotAmount * singleSlotPrice - Math.floor(slotAmount / 4) * 10}
            </div>

            <div style={{ color: "#add8ff" }} className="mt-3">
              Slot available to use: {parentUserAvailableSlot}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
