import { Link, useNavigate } from "react-router-dom";
import {
  EVENTS_TYPE,
  LOCAL_STORAGE_KEYS,
  RANDOM_AVATAR_URLS,
} from "../../utils/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import TransparentCard from "../common/TransparentCard";
import { format } from "date-fns";

// TODO further break down into smaller components
export default function EventsList({ eventsData, label = false, findMyKids }) {
  const navigate = useNavigate();

  return (
    <div className="w-full">
      {/* title */}
      {label && (
        <div
          className="w-full text-primary flex items-center flex-row mt-5 mb-4"
          style={{ justifyContent: "space-between" }}
        >
          <h1 style={{ fontWeight: "700" }}>Upcoming Events</h1>
          <Link to={`/${LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG}/eventscalender`}>
            <h1 style={{ fontWeight: "700" }}>
              See All{" "}
              <FontAwesomeIcon icon={faCircleArrowRight} />
            </h1>
          </Link>
        </div>
      )}
      {/* DATE + EVENT info + BOOK session button */}
      <div className="space-y-4">
        {eventsData?.map((event, index) => {
          return (
            <TransparentCard border key={index} className="pl-2 pr-4 py-4">
              <div
                className={`flex justify-between items-center font-display text-white text-lg`}
              >
                {/* DATE & Event INFO */}
                <div
                  style={{
                    display: "flex",
                  }}
                  className="py-2"
                >
                  {/* DATE section */}
                  <div
                    style={{
                      color: "white",
                      width: "80px",
                      borderRight: "1px dotted #ffffff45",
                      paddingLeft: "15px",
                      paddingRight: "10px",
                    }}
                    className="text-xl font-bold"
                  >
                    {format(new Date(event.dateTime), "EEE MMM dd")}
                  </div>

                  {/* Schdule INFO section */}
                  <div className="font-semibold ml-5">
                    {/* TIME */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className="w-3 h-3 rounded-full bg-blue flex items-center justify-center">
                        <div className="w-1 h-1 rounded-full bg-black"></div>
                      </div>
                      {/* Time value */}
                      <div className="text-sm ml-2 text-white">
                        {format(new Date(event.dateTime), "h:mm aa")}
                      </div>
                    </div>

                    {event.eventType == EVENTS_TYPE.SPECIAL_EVENTS ? (
                      <>
                        {event.caption ? (
                          <div className="text-xl mt-1 mb-2">
                            {event.caption}
                          </div>
                        ) : (
                          <div className="text-xl mt-1 mb-2">Special Event</div>
                        )}
                      </>
                    ) : (
                      <>
                        {/* CAPTION */}
                        <div className="text-sm">
                          Event Type:{" "}
                          <b style={{ color: "#ccd0e0" }}>{event.eventType}</b>
                        </div>

                        {/* CAPTION */}
                        {event.caption && (
                          <div className="text-sm">
                            Title:{" "}
                            <b style={{ color: "#ccd0e0" }}>{event.caption}</b>
                          </div>
                        )}
                      </>
                    )}

                    {/* DURATIOON */}
                    {event.duration && (
                      <div className="text-sm">
                        Duration:{" "}
                        <b style={{ color: "#ccd0e0" }}>{event.duration}</b>
                      </div>
                    )}

                    {/* SPORTS */}
                    {event.sports && event.sports.length > 0 && (
                      <div className="text-sm">
                        Sports:{" "}
                        <b style={{ color: "#ccd0e0" }}>
                          {event.sports.join(", ")}
                        </b>
                      </div>
                    )}

                    {/* GAME TYPE */}
                    {event.gameType && (
                      <div className="text-sm">
                        Game Type:{" "}
                        <b style={{ color: "#ccd0e0" }}>{event.gameType}</b>
                      </div>
                    )}

                    {/* SLOT */}
                    {event.slot && (
                      <div className="text-sm">
                        #SLOT: <b style={{ color: "#ccd0e0" }}>{event.slot}</b>
                      </div>
                    )}

                    {/* PRICE */}
                    {event.eventPrice && (
                      <div
                        className="text-md mt-2"
                        style={{ color: "#00fff5" }}
                      >
                        Price: ${event.eventPrice}
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className="mr-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* BOOKED KIDS */}
                  <div style={{ display: "flex" }}>
                    {findMyKids?.findKidsForParent
                      ?.filter((kid) =>
                        event.participantKidIds.includes(kid._id)
                      )
                      .map((kid, index) => {
                        return (
                          <button
                            key={index} // replace with id
                            className="mr-2 cursor-pointer"
                            onClick={() => {
                              navigate(`/kid-details/${kid.player._id}`);
                            }}
                          >
                            <img
                              src={
                                RANDOM_AVATAR_URLS[kid.avatar]
                                  ? RANDOM_AVATAR_URLS[kid.avatar]
                                  : RANDOM_AVATAR_URLS["user4_asset4"]
                              }
                              alt="Basement Sports"
                              className="object-contain h-8"
                            />
                          </button>
                        );
                      })}
                  </div>

                  {/* JOIN */}
                  <button
                    className="bg-dark-green text-xs font-bold py-2 px-5 rounded-full whitespace-nowrap"
                    style={{
                      backgroundColor: "#0000008a",
                      color: "#FFFFFF",
                    }}
                    onClick={async () => {
                      navigate(`/event-details/${event._id}`);
                    }}
                  >
                    {findMyKids?.findKidsForParent?.filter((kid) =>
                      event.participantKidIds.includes(kid._id)
                    ).length > 0
                      ? "Booked"
                      : "BOOK SESSION"}
                  </button>
                </div>
              </div>
            </TransparentCard>
          );
        })}
      </div>

      {(!eventsData || eventsData?.length == 0) && (
        <div className="text-center mt-14">No events yet!</div>
      )}
    </div>
  );
}
