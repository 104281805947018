import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/free-solid-svg-icons";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";
import { useQuery, useMutation } from "@apollo/client";
import { Link, useNavigate, useParams } from "react-router-dom";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import Select from "react-select";
import { debounce } from "lodash";

// Query
import FIND_ALL_VIDEOS from "../../gql/query/base/findAllVideosByFilter.query";
import FIND_MY_KIDS from "../../gql/query/base/findKidsForParent.query";

import {
  LOCAL_STORAGE_KEYS,
  RANDOM_AVATAR_URLS,
  OTHER_VIDEO_TYPES_TO_FETCH,
} from "../../utils/constant";
import useGetRecordings from "../../gql/hooks/useGetRecordings";
import { extractIdFromLink } from "../../utils/extractVideoIdFromLink";

const VideoHighlights = () => {
  // Common data
  const navigate = useNavigate();

  // FILTER states
  const [isSelectedBase, setIsSelectedBase] = useState(true);
  const [selectedKidIds, setSelectedKidIds] = useState([]);

  // Search filter STATE
  const [searchVideoName, setSearchVideoName] = useState("");

  // Dropdown SPORT filter states
  const [selectedSportForComponent, setSelectedSportForComponent] = useState({
    value: "all",
    label: "All Sport",
  });

  // Dropdown Options of Sports
  const sportsOption = [
    { value: "all", label: "All Sport" },
    { value: "soccer", label: "Soccer" },
    { value: "hockey", label: "Hockey" },
    { value: "baseBall", label: "Baseball" },
    { value: "basketBall", label: "Basketball" },
  ];

  // Set custom style in react-select
  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black", // Set the color for all options
    }),
  };
  const {
    data: allHighlights,
    loading: loadingHighlights,
    error: errorLoadingHighlights,
  } = useGetRecordings();

  // Query
  const {
    data: findAllVideos,
    loading: findAllVideosLoading,
    error: findAllVideosError,
    refetch: refetchAllVideoHighlights,
  } = useQuery(FIND_ALL_VIDEOS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: findMyKids,
    loading: findMyKidsLoading,
    error: findMyKidsError,
    refetch: findMyKidsRefetch,
  } = useQuery(FIND_MY_KIDS, {
    variables: {
      parentId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
    },
    skip: !localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
  });

  // Handle Sports dropdown
  const handleSelectSportChange = (selectedOptions) => {
    setSelectedSportForComponent(selectedOptions);

    // Refetch data
    refetchAllVideoHighlights({
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      sport: selectedOptions.value,
      searchName: null,
      kidPlayerIds: [],
    });

    // Update STATE
    setSelectedKidIds([]);
    setIsSelectedBase(true);
    setSearchVideoName("");
  };

  // search event by name
  const searchVideosByName = debounce(async (searchName) => {
    // If "searchName" has a valid string. ELSE search by today date
    refetchAllVideoHighlights({
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      searchName,
      sport: "all",
      kidPlayerIds: [],
    });

    // Update STATE
    setSelectedKidIds([]);
    setIsSelectedBase(true);
    setSelectedSportForComponent({
      value: "all",
      label: "All Sport",
    });
  }, 400);

  if (errorLoadingHighlights) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  return (
    <CommonBackdrop rootName="Bases" pageName="Highlights">
      <div className="" style={{ minHeight: "400px" }}>
        {/* FILTER */}
        {!findMyKidsLoading && (
          <div
            className="mt-4 flex items-center font-display text-yellow-300 text-lg px-2 py-2 flex-wrap"
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#00000033",
              borderRadius: "5px",
            }}
          >
            {/* FILTER LEFT PANEL */}
            {/* FILTER LEFT PANEL */}
            <div className="flex">
              {/* ✅ Filter option: "Anar Studio" */}
              <div
                className="px-5 py-2 mr-2 cursor-pointer"
                style={{
                  backgroundColor: "#000000",
                  borderRadius: "50px",
                  opacity: isSelectedBase ? 1 : 0.5,
                }}
                data-tooltip-id="tooltip-content"
                data-tooltip-content="Filter videos by Base"
              >
                Anar Studio
              </div>

              {/* ✅ Filter option: "My Kids" */}
              {findMyKids?.findKidsForParent && (
                <div
                  className="px-5 py-2 mr-3 cursor-pointer"
                  style={{
                    backgroundColor: "#000000",
                    borderRadius: "50px",
                    opacity:
                      selectedKidIds.length ==
                      findMyKids?.findKidsForParent?.map((kid) => kid._id)
                        .length
                        ? 1
                        : 0.5,
                  }}
                  data-tooltip-id="tooltip-content"
                  data-tooltip-content="Filter videos by kids"
                  onClick={() => {
                    // create query options
                    let query = {};
                    // If BASE was selected then set "baseSlug". Else, set it to null
                    if (isSelectedBase) {
                      query = {
                        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                        sport: "all",
                        searchName: null,
                      };
                    } else {
                      query = {
                        baseSlug: null,
                      };
                    }
                    // If previously no kid was selected, then insert all KIDS into array. Else, no need to add the "kidPlayerIds" in QUERY
                    if (selectedKidIds.length == 0) {
                      query = {
                        ...query,
                        kidPlayerIds: findMyKids?.findKidsForParent?.map(
                          (kid) => kid._id
                        ),
                      };
                    } else {
                      query = { ...query, kidPlayerIds: null };
                    }

                    refetchAllVideoHighlights(query);

                    // update state
                    if (selectedKidIds.length == 0) {
                      setSelectedKidIds(
                        findMyKids?.findKidsForParent?.map((kid) => kid._id)
                      );
                    } else {
                      setSelectedKidIds([]);
                      setIsSelectedBase(true);
                    }

                    // Update other FILTER STATE
                    setSearchVideoName("");
                    setSelectedSportForComponent({
                      value: "all",
                      label: "All Sport",
                    });
                  }}
                >
                  My Kids
                </div>
              )}

              {/* ✅ Filter option: "KIDs" */}
              {!findMyKidsLoading &&
                !findMyKidsError &&
                findMyKids &&
                findMyKids.findKidsForParent &&
                findMyKids.findKidsForParent.length > 0 && (
                  <div style={{ display: "flex" }}>
                    {findMyKids.findKidsForParent?.map((kid) => {
                      return (
                        <div
                          className="cursor-pointer mr-3"
                          style={{
                            opacity: selectedKidIds.includes(kid._id) ? 1 : 0.5,
                          }}
                          data-tooltip-id="tooltip-content"
                          data-tooltip-content="Filter videos by this kid"
                          onClick={() => {
                            // create query options
                            let query = {};
                            if (isSelectedBase) {
                              query = {
                                baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                                sport: "all",
                                searchName: null,
                              };
                            }

                            let finalKidPlayerIdsToFetch = [];
                            // update selected KIDs
                            if (selectedKidIds.includes(kid._id)) {
                              finalKidPlayerIdsToFetch = selectedKidIds.filter(
                                (playerId) => playerId != kid._id // Removed this Kid
                              );
                            } else {
                              finalKidPlayerIdsToFetch = [
                                ...selectedKidIds,
                                kid._id,
                              ]; // Add this kid to fetch videos
                            }

                            // Now ready QUERY option to filter
                            if (finalKidPlayerIdsToFetch.length > 0) {
                              query = {
                                ...query,
                                kidPlayerIds: finalKidPlayerIdsToFetch,
                              };
                            } else {
                              query = {
                                ...query,
                                kidPlayerIds: [],
                              };
                            }

                            // Re-fetch videos
                            refetchAllVideoHighlights(query);

                            // Update STATE
                            setSelectedKidIds(finalKidPlayerIdsToFetch);
                            if (finalKidPlayerIdsToFetch == 0) {
                              setIsSelectedBase(true);
                            }

                            // Update other FILTER STATE
                            setSearchVideoName("");
                            setSelectedSportForComponent({
                              value: "all",
                              label: "All Sport",
                            });
                          }}
                        >
                          <img
                            src={
                              RANDOM_AVATAR_URLS[kid.avatar]
                                ? RANDOM_AVATAR_URLS[kid.avatar]
                                : RANDOM_AVATAR_URLS["user4_asset4"]
                            }
                            alt="Basement Sports"
                            className="max-h-12 object-contain"
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
            </div>

            {/* FILTER RIGHT PANEL */}
            {/* FILTER RIGHT PANEL */}
            <div className="flex" style={{ alignItems: "center" }}>
              <div
                className="mr-2"
                data-tooltip-id="tooltip-content"
                data-tooltip-content="Filter videos by Sport"
              >
                <Select
                  className="w-40"
                  value={selectedSportForComponent}
                  onChange={handleSelectSportChange}
                  options={sportsOption}
                  isMulti={false}
                  styles={customStyles}
                />
              </div>

              <input
                className="w-56 border-2 border-slate-500 px-2 py-1 bg-transparent rounded text-white font-bold font-display"
                placeholder="Search videos by name"
                onChange={(e) => {
                  // Update STATE
                  setSearchVideoName(e.target.value);
                  // Call to re-fetch with debounce
                  searchVideosByName(e.target.value);
                }}
                value={searchVideoName}
                data-tooltip-id="tooltip-content"
                data-tooltip-content="Search videos by name"
              />
            </div>
          </div>
        )}

        {/* VIDEOS */}
        <div className="font-semibold my-auto mt-5">
          <h1 className="text-xl" style={{ color: "white" }}>
            Highlights
          </h1>
          {/* videos */}
          <div
            className="flex flex-wrap"
            style={{
              alignItems: "center",
            }}
          >
            {loadingHighlights && (
              <div style={{ display: "block", margin: "auto" }}>
                <DataLoading loadingType="success" />
              </div>
            )}
            {!loadingHighlights &&
              allHighlights?.map((video, index) => {
                return (
                  <div
                    className="w-full md:w-1/3"
                    style={{
                      justifyContent: "center",
                    }}
                    key={video?._id}
                  >
                    <div
                      className="mr-3 my-3 cursor-pointer"
                      style={{
                        position: "relative",
                        borderRadius: "10px",
                        overflow: "hidden",
                      }}
                      onClick={() =>
                        navigate(
                          `/video/${extractIdFromLink(
                            video?.url
                          )}?otherVideoTypes=${
                            isSelectedBase
                              ? OTHER_VIDEO_TYPES_TO_FETCH.fetchSameBaseVideos
                              : OTHER_VIDEO_TYPES_TO_FETCH.fetchSamePlayerVideos
                          }`
                        )
                      }
                    >
                      {/* <div className="rounded-lg py-1 px-2 lg:px-6 shadow-md shadow-black/30 my-3"> */}
                      <img
                        src={
                          video?.videoThumbnailUrl
                            ? video.videoThumbnailUrl
                            : "https://res.cloudinary.com/dvqldxdfv/image/upload/v1735731167/hr8opn3nlhpccuwqoh5b.png"
                        }
                        alt="Basement Sports"
                        className={`md:block w-[90px] object-cover `}
                        style={{ width: "100%", height: "200px" }}
                      />

                      {/* "https://res.cloudinary.com/dvqldxdfv/image/upload/v1682695432/Screenshot_2023-04-28_at_9.09.42_srr2ch.png" */}
                      {/* PLAY ICON */}
                      <div
                        style={{
                          color: "yellow",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FontAwesomeIcon icon={faCirclePlay} size="3x" />
                      </div>

                      {/* video details */}
                      <div
                        style={{
                          backgroundColor: "#00000033",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        className="px-4 py-4"
                      >
                        {/* left panel */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {/* avatar */}
                          <img
                            src={
                              RANDOM_AVATAR_URLS[video?.userDetails?.avatar]
                                ? RANDOM_AVATAR_URLS[video?.userDetails?.avatar]
                                : RANDOM_AVATAR_URLS["user4_asset4"]
                            }
                            alt="Basement Sports"
                            className={`md:block w-[90px] mr-2`}
                            style={{ width: "40px", height: "40px" }}
                          />
                          {/* video caption & base name */}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {/* video caption */}
                            <div
                              className="text-sm"
                              style={{
                                fontWeight: "700",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                minWidth: "50px",
                                maxWidth: "140px",
                              }}
                            >
                              {video.caption}
                            </div>
                            {/* base name */}
                            <div className="text-sm" style={{ opacity: "0.5" }}>
                              @{"base"}
                            </div>
                          </div>
                        </div>

                        {/* right panel */}
                        <div style={{ display: "flex" }}>
                          <div className="text-xs" style={{ opacity: "0.5" }}>
                            {formatDistanceToNow(
                              new Date(video?.streamStartedAt ?? video?.updatedAt)
                            )}{" "}
                            ago
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            {!loadingHighlights && allHighlights?.length == 0 && (
              <div>No recorded highlights yet</div>
            )}
          </div>
        </div>
      </div>
    </CommonBackdrop>
  );
};

export default VideoHighlights;
