import React, { useState, useContext, useEffect } from "react";
import { collection_data } from "../../data/collection_data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faUsers,
  faRotateBack,
} from "@fortawesome/free-solid-svg-icons";
import { ShoppingCardContext } from "../../context/cardContext";
import { Tab, TabList } from "react-tabs";
import { WOMEN_NFT_COUNTRIES, NFT_COUNTRIES } from "../../utils/nft_countries";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";
import { useQuery, useMutation } from "@apollo/client";
import { format, formatISO } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import Modal from "react-modal";
import { debounce, groupBy } from "lodash";
import ClipLoader from "react-spinners/ClipLoader";
import { utcToZonedTime, format as tzFormat } from "date-fns-tz";

// Query
import FIND_BASE_EVENTS_BY_FILTER from "../../gql/query/base/findBaseEventsByFilter.query";
import FIND_MY_KIDS from "../../gql/query/base/findMyAllKidsToSubscribeBase.query";
import FIND_BASE_DETAILS from "../../gql/query/base/findBaseDetailsForBaseDashboard.query";
import FIND_AVAILABLE_SLOTS from "../../gql/query/base/findAvailableSlots.query";

// MUTATION
import PARTICIPATE_EVENT from "../../gql/mutation/base/participateEvent.mutations";
import INITIATE_STRIPE_SESSION from "../../gql/mutation/base/initiateStripeSession.mutations";
import INITIATE_STRIPE_BILLING_PORTAL_SESSION from "../../gql/mutation/base/initiateStripeBillingPortalSession.mutations";
import CHECK_EVENT_COUPON_CODE_VALIDITY from "../../gql/mutation/base/checkEventCouponValidity.mutations";
import ENROLL_KIDS_USING_SLOT from "../../gql/mutation/base/enrollKidsUsingSlot.mutations";
import {
  LOCAL_STORAGE_KEYS,
  EVENT_PAY,
  PAYMENT_FOR,
  EACH_NFT_AFFILIATE_COUPON_DISCOUNT_PRICE_FOR_ALL_USER_IN_DOLLAR,
  SUPER_BASE_COUPON_CODE_INFO,
  RANDOM_AVATAR_URLS,
  PROJECT_BSPORTS_SUPER_BASE_SINGLE_EVENT_PURCHASE,
  PROJECT_BSPORTS_SUPER_BASE_MONTHLY_SUBSCRIPTION,
  EVENTS_TYPE,
  EVENTS_FILTER_BY,
  MONTHS_BY_NUMBER,
  DAY_ORDER,
} from "../../utils/constant";
import HeadLine from "../../component/bSports/HeadLine";
import { da } from "date-fns/locale";
import ClientSIdeEventCard from "../../component/events/clientSIdeEventCard";
import EventsList from "../../component/events/EventsList";
import FIND_UPCOMING_EVENTS from "../../gql/query/base/findUpcomingEvents.query";
import SingleEventPromotion from "../../component/events/SingleEventPromotion";

const EventsCalender = () => {
  // #region logic
  const navigate = useNavigate();

  const { card, setCard } = useContext(ShoppingCardContext);

  // Modal STATE
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedEventDetails, setSelectedScheDetail] = useState(null);
  const [selectedKidIds, setSelectedKidIds] = useState([]);
  const [isBookFullSeason, setIsBookFullSeason] = useState(false);

  // Special Event Purchase Modal (For Coupon Code entry)
  const [specialEventModalIsOpen, setIsOpenSpecialEventModal] = useState(false);

  // Coupon Code State
  const [showCouponBox, setShowCouponBox] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeCheckResponse, setCouponCodeCheckResponse] = useState("");
  const [totalDiscountInDollar, setTotalDiscountInDollar] = useState(0);

  // Event price state
  const [selectedEventPrice, setSelectedEventPrice] = useState(0);

  // filtered Events data
  const [filteredEventsData, setFilteredEventsData] = useState(null);

  // Calender State
  const [dateValue, setDateValue] = useState("");
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedFilterType, setFilterType] = useState(EVENTS_FILTER_BY.DATE);

  // Search filter STATE
  const [searchEventName, setSearchEventName] = useState("");

  // Day view STATE
  const [isDayView, setIsDayView] = useState(false);

  // Spinner loading css
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  NFT_COUNTRIES.sort((a, b) =>
    a.name.charAt(0).localeCompare(b.name.charAt(0))
  );

  WOMEN_NFT_COUNTRIES.sort((a, b) =>
    a.name.charAt(0).localeCompare(b.name.charAt(0))
  );

  // Query
  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
    refetch: eventsRefetch,
  } = useQuery(FIND_BASE_EVENTS_BY_FILTER, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      particularDate: dateValue,
      filterBy: EVENTS_FILTER_BY.DATE,
      queryFromClient: true,
      userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
    },
    notifyOnNetworkStatusChange: true,
  });

  

  const {
    data: availableSlots,
    loading: availableSlotsLoading,
    error: availableSlotsError,
    refetch: availableSlotsRefetch,
  } = useQuery(FIND_AVAILABLE_SLOTS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      selectedDate: dateValue,
    },
  });

  const {
    data: findMyKids,
    loading: findMyKidsLoading,
    error: findMyKidsError,
    refetch: findMyKidsRefetch,
  } = useQuery(FIND_MY_KIDS, {
    variables: {
      parentId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
    skip: !localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
  });

  const {
    data: baseData,
    loading: baseLoading,
    error: baseError,
    refetch: baseRefetch,
  } = useQuery(FIND_BASE_DETAILS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  // MUTATION
  const [
    participateEvent,
    { loading: participateEventLoading, error: participateEventError },
  ] = useMutation(PARTICIPATE_EVENT);

  const [
    initiateStripeSessionMutation,
    {
      loading: initiateStripeSessionMutationLoading,
      error: initiateStripeSessionMutationError,
    },
  ] = useMutation(INITIATE_STRIPE_SESSION);

  const [
    initiateStripeBillingPortalSessionMutation,
    {
      loading: initiateStripeBillingPortalSessionMutationLoading,
      error: initiateStripeBillingPortalSessionMutationError,
    },
  ] = useMutation(INITIATE_STRIPE_BILLING_PORTAL_SESSION);

  const [
    checkEventCouponValidity,
    { loading: loadingCheckCouponValidity, error: errorCheckCouponValidity },
  ] = useMutation(CHECK_EVENT_COUPON_CODE_VALIDITY);

  const [
    enrollKidsUsingSlot,
    { loading: enrollKidsUsingSlotLoading, error: enrollKidsUsingSlotError },
  ] = useMutation(ENROLL_KIDS_USING_SLOT);

  // Refetch event data
  useEffect(() => {
    function refetchEventData() {
      findMyKidsRefetch();
      eventsRefetch();
      availableSlotsRefetch({
        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
        selectedDate: dateValue,
      });
    }
    refetchEventData();
  }, []);

  // Set day view events data
  useEffect(() => {
    function manipulateEventsData() {
      if (eventsData?.findBaseEventsByFilter) {
        // First add a new day property in each objects
        const dataWithDayName = eventsData?.findBaseEventsByFilter.map(
          (item) => {
            return {
              ...item,
              dayName: format(new Date(item.dateTime), "EEEE"),
            };
          }
        );

        // Then group by that dayName
        const groupedEvents = groupBy(dataWithDayName, (obj) => obj.dayName);

        // convert the object of arrays to an array of entries
        const entries = Object.entries(groupedEvents);

        // sort the array of entries by using the dayOrder object
        const sortedEntries = entries.sort((a, b) => {
          // get the order of the day names for each entry
          const orderA = DAY_ORDER[a[0]];
          const orderB = DAY_ORDER[b[0]];
          // compare the order and return the result
          return orderA - orderB;
        });

        // convert the sorted array of entries back to an object of arrays
        const sortedData = Object.fromEntries(sortedEntries);

        // Now set the final data
        setFilteredEventsData(sortedData);
      }
    }
    manipulateEventsData();
  }, [eventsData]);

  async function joinEventAsASubscriptionUser(eventId, kidIds) {
    console.log(kidIds, "okk");
    const { data } = await participateEvent({
      variables: {
        userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
        eventId,
        kidIds,
      },
    });
    if (data.participateEvent.errorMessage == null) {
      toast.success("Successfully enrolled into this event!");
      eventsRefetch();
      setSelectedKidIds([]);
      setIsOpen(false);
      return;
    } else {
      toast.error(data.participateEvent.errorMessage);
      return;
    }
  }

  // Check CouponCode Validity
  const checkCouponValidity = debounce(
    async ({ couponCode, eventId, paymentFor }) => {
      console.log("ASCEEEE DEBOUNCE");
      // Set coupon code
      setCouponCode(couponCode);
      // Call to backend for checking this coupon code
      const { data } = await checkEventCouponValidity({
        variables: {
          couponCode,
          eventId,
          paymentFor,
        },
      });
      // If coupon code is correct then set discount price
      if (data.checkEventCouponValidity.errorMessage == null) {
        setCouponCodeCheckResponse(
          data.checkEventCouponValidity.successMessage
        );
        setTotalDiscountInDollar(
          data.checkEventCouponValidity?.additionalData
            ? data.checkEventCouponValidity.additionalData
            : 0
        );
      } else if (data.checkEventCouponValidity?.errorMessage) {
        setTotalDiscountInDollar(0);
        setCouponCodeCheckResponse(data.checkEventCouponValidity.errorMessage);
      }
    },
    600
  );

  // Single Event Purchase (only special events)
  // Call the Mutation to get STRIPE session URL to redirect into STRIPE payment gateway
  async function initiateStripeCheckoutUiForSpecialEvent() {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) {
      // Get my all kid IDs
      let kidIds = findMyKids?.findMyAllKidsToSubscribeBase?.map(
        (kid) => kid._id
      );
      // Call mutation to get STRIPE session url
      const { data } = await initiateStripeSessionMutation({
        variables: {
          project: PROJECT_BSPORTS_SUPER_BASE_SINGLE_EVENT_PURCHASE,
          userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
          eventId: selectedEventId,
          kidIds, // Make all of my kids enrolled into this event
          couponCode,
          isBookFullSeason,
        },
      });
      if (data.initiateStripeSession.errorMessage == null) {
        // Close Modal
        closeSpecialEventModal();
        // redirect to STRIPE gateway
        window.location.href = data.initiateStripeSession.additionalData; // Here additionalData is the STRIPE checkout url (generated by our server)
      } else {
        toast.error(data.initiateStripeSession.errorMessage);
      }
    } else {
      // As user is not logged in, so navigate the user to login page
      navigate(`/login`);
      return;
    }
  }

  // Single Event Purchase (except special events)
  async function initiateStripeCheckoutUi(eventId, parentUserAvailableSlot) {
    if (!selectedKidIds || selectedKidIds.length == 0) {
      toast.error("Please select a KID to join the event!");
      return;
    }
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) {
      // If slots available then enroll kids via slots
      if (parentUserAvailableSlot > 0) {
        // Else Call the Mutation to get STRIPE session URL
        const { data } = await enrollKidsUsingSlot({
          variables: {
            parentId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
            eventId,
            kidIds: selectedKidIds,
          },
        });
        if (data.enrollKidsUsingSlot.errorMessage == null) {
          baseRefetch();
          findMyKidsRefetch();
          eventsRefetch();
          // Off modal
          setSelectedKidIds([]);
          setIsOpen(false);
          toast.success(data.enrollKidsUsingSlot.successMessage);
        } else {
          toast.error(data.enrollKidsUsingSlot.errorMessage);
        }
      } else {
        // Call the Mutation to get STRIPE session URL
        const { data } = await initiateStripeSessionMutation({
          variables: {
            project: PROJECT_BSPORTS_SUPER_BASE_SINGLE_EVENT_PURCHASE,
            userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
            eventId,
            kidIds: selectedKidIds,
            couponCode,
          },
        });
        if (data.initiateStripeSession.errorMessage == null) {
          setSelectedKidIds([]);
          setIsOpen(false);
          window.location.href = data.initiateStripeSession.additionalData; // Here additionalData is the STRIPE checkout url (generated by our server)
        } else {
          toast.error(data.initiateStripeSession.errorMessage);
        }
      }
    } else {
      // As user is not logged in, so navigate the user to login page
      navigate(`/login`);
      return;
    }
  }

  // Handle Calender Actions
  function handleClickDay(date) {
    // Today date
    const currentDate = new Date();

    // First convert the date from STRING to DATE format
    const selectedDate = new Date(date);

    // Set current Hour, Minute, Second (As DatePicker can't handle it)
    selectedDate.setHours(currentDate.getHours());
    selectedDate.setMinutes(currentDate.getMinutes());
    selectedDate.setSeconds(currentDate.getSeconds());

    let formattedDate = formatISO(selectedDate, { representation: "complete" });

    setDateValue(formattedDate);

    // Update filter STATE
    setFilterType(EVENTS_FILTER_BY.DATE);
    setSearchEventName("");
  }

  function handleOnActiveStartDateChange(view) {
    // Refetch data by MONTH
    eventsRefetch({
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      year: view.activeStartDate.getFullYear(),
      month: view.activeStartDate.getMonth(),
      filterBy:
        selectedFilterType == EVENTS_FILTER_BY.RECURRING_EVENTS
          ? EVENTS_FILTER_BY.RECURRING_EVENTS
          : EVENTS_FILTER_BY.MONTH,
      queryFromClient: true,
      userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
    });

    // Update filter STATE
    setFilterType(
      selectedFilterType == EVENTS_FILTER_BY.RECURRING_EVENTS
        ? EVENTS_FILTER_BY.RECURRING_EVENTS
        : EVENTS_FILTER_BY.MONTH
    );

    // Update Year & Month
    setSelectedYear(view.activeStartDate.getFullYear());
    setSelectedMonth(view.activeStartDate.getMonth());
  }

  // Modal functions
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);

    // Make coupon code info as empty for safety
    setShowCouponBox(false);
    setCouponCode("");
    setCouponCodeCheckResponse("");
    setTotalDiscountInDollar(0);
  }

  // Special Event Modal functions
  function closeSpecialEventModal() {
    setIsOpenSpecialEventModal(false);

    // Make coupon code info as empty for safety
    setShowCouponBox(false);
    setCouponCode("");
    setCouponCodeCheckResponse("");
    setTotalDiscountInDollar(0);
    setSelectedScheDetail(null);
  }

  // search event by name
  const searchEventByName = debounce(async (searchName) => {
    // If "searchName" has a valid string. ELSE search by today date
    if (searchName && searchName != "") {
      // Update STATE
      setFilterType(EVENTS_FILTER_BY.SEARCH_NAME);

      // Call to backend for checking this coupon code
      eventsRefetch({
        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
        searchName,
        filterBy: EVENTS_FILTER_BY.SEARCH_NAME,
        queryFromClient: true,
        userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
      });
    } else {
      // Update STATE
      setFilterType(EVENTS_FILTER_BY.DATE);

      // Call to backend for checking this coupon code
      eventsRefetch({
        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
        particularDate:
          !dateValue || dateValue == "" ? new Date() : new Date(dateValue), // Check if "dateValue" has value. If not then set today date as "particularDate" & refetch. ELSE IF "dateValue" has value, then refetch with that selected date
        filterBy: EVENTS_FILTER_BY.DATE,
        queryFromClient: true,
        userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
      });
    }
  }, 400);

  // Go to event details
  function goToEventDetails(e, eventId) {
    // Stop propagating to parent on CLICK
    e.stopPropagation();

    navigate(`${`/event-details/${eventId}`}`);
  }

  // Go to event details
  function goToEventEditPage(e, eventId) {
    // Stop propagating to parent on CLICK
    e.stopPropagation();

    navigate(`${`/update-event/${eventId}`}`);
  }

  // Go to event details
  function goToKidDetails(e, kidPlayerId) {
    // Stop propagating to parent on CLICK
    e.stopPropagation();
    navigate(`/kid-details/${kidPlayerId}`);
  }

  // Handle to book session from event cards
  function bookSessionFromEventCard({ e, event, isBookFullSeason = false }) {
    // Stop propagating to parent on CLICK
    e.stopPropagation();

    // If, not logged in then re-direct to login page
    // Else, if Special EVENT, then open special event purchase modal
    // // If, already booked then show "Error Alert"
    // // Else, Open Modal
    // Else, if no kids found then re-direct to add-kid page
    // Else, open enrol kids Modal to enrol kids for Normal Events
    if (
      !localStorage.getItem(
        LOCAL_STORAGE_KEYS.USER_ID // ✅ If Not Logged In
      )
    ) {
      navigate(`/login`);
    } else if (
      event.eventType == EVENTS_TYPE.SPECIAL_EVENTS // ✅ If special events
    ) {
      if (
        findMyKids?.findMyAllKidsToSubscribeBase?.filter((kid) =>
          event.participantKidIds.includes(kid._id)
        ).length > 0 ||
        (event.bookedBy == localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID) &&
          !isBookFullSeason) // ✅ If special events & if already booked special event
      ) {
        toast.error("You have allready booked this event");
        return;
      } else {
        // ✅ If special events & if not yet booked special event then open Special Event Modal to input Coupon Code
        setSelectedEventId(
          isBookFullSeason
            ? event?.hasRecurring == true
              ? event._id
              : event?.recurringFromEventId
            : event._id
        );
        // Set event price
        setSelectedEventPrice(event.eventPrice);
        // set event details
        setSelectedScheDetail(event);
        // Set isBookFullSeason
        setIsBookFullSeason(isBookFullSeason);
        // Open Modal
        setIsOpenSpecialEventModal(true);
      }
    } else if (
      // ✅ If it is not a special event but no kid found (As Kids need to be selected for Other events from Modal)
      findMyKids?.findMyAllKidsToSubscribeBase?.length < 1
    ) {
      navigate("/add-kid");
    } else {
      // ✅ If it is not a special event & kid found
      // Set IDs for getting data
      setSelectedEventId(event._id);
      setSelectedScheDetail(event);

      // Make coupon code info as empty for safety
      setCouponCode("");
      setCouponCodeCheckResponse("");
      setTotalDiscountInDollar(0);

      // Set event price
      setSelectedEventPrice(event.eventPrice);

      // Open Modal
      openModal();
    }
  }

  function SeasonDetails({
    promoCodes,
    eventPrice,
    recurringStartDate,
    recurringEndDate,
    recurringDays,
    recurringEventIds,
    seasonPrice,
    discount,
  }) {
    return (
      <div className="mx-4 my-8" style={{ borderTop: "1px dotted #ffffff45" }}>
        {/* season title & coupon code for discount */}
        <div
          className="text-2xl mt-3 mb-1 flex items-center"
          style={{ color: "#00ff1b" }}
        >
          <b className="whitespace-nowrap">Season Details</b>
          {discount > 0 && (
            <div className="text-sm ml-2" style={{ color: "white" }}>
              ({" "}
              <b className="font-bold text-yellow-300">
                {((discount / (seasonPrice + discount)) * 100).toFixed(2)}%{" "}
              </b>{" "}
              off on a full-season purchase )
            </div>
          )}
        </div>
        {/* dcurring from & to dates */}
        <div className="my-2" style={{ color: "white" }}>
          From{" "}
          <b className="text-cyan-300">
            {format(Number(recurringStartDate), "do MMM',' yyyy")}
          </b>{" "}
          To{" "}
          <b className="text-cyan-300">
            {format(Number(recurringEndDate), "do MMM',' yyyy")}
          </b>
        </div>
        {/* recurring days */}
        <div className="flex items-center">
          <div>On Every</div>
          <div className="flex items-center">
            {recurringDays.map((day) => (
              <div className="ml-2 px-2 py-1 bg-green-600 rounded-md font-bold">
                {day}
              </div>
            ))}
          </div>
        </div>
        {/* total events */}
        <div
          className="flex items-center justify-between"
          style={{ color: "white" }}
        >
          <div>
            Total Events:
            <b className="ml-2 text-yellow-300">
              {recurringEventIds?.length ? recurringEventIds.length : 0}
            </b>
          </div>
        </div>
      </div>
    );
  }

  if (findMyKidsLoading || baseLoading)
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  if (eventsError || findMyKidsError || baseError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  // Set available slot to use

  let allSlotPurchased = baseData?.findBaseDetails?.slotPurchased;
  let parentUserAvailableSlot = 0;

  if (allSlotPurchased) {
    let parentUserSlotInfoIndex = allSlotPurchased.findIndex(
      (item) =>
        item.parentId === localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)
    );

    if (parentUserSlotInfoIndex !== -1) {
      parentUserAvailableSlot =
        allSlotPurchased[parentUserSlotInfoIndex].slotAvailable;
    }
  }

  // endregion

  return (
    <CommonBackdrop
      rootName="Schedule"
      pageName="Events Calendar"
      Button={AddEventButton}
    >
      <div>
        <div className="md:w-full sm:w-full sx:w-full w-full ">
          <section className="">
            {/* <div
              className="w-full bg-black/20 px-5 py-1 h-fit flex items-center flex-row mt-5"
              style={{ justifyContent: "space-between" }}
            >
              <div className="flex items-center">
                <h1 className="text-yellow-300">Event / </h1>
                <h1> Events Calendar</h1>
              </div>

              <div
                className="bg-yellow-800 py-1 px-2 rounded cursor-pointer"
                onClick={() => {
                  if (
                    !localStorage.getItem(
                      LOCAL_STORAGE_KEYS.USER_ID // ✅ If Not Logged In
                    )
                  ) {
                    navigate(`/login`);
                    return;
                  }

                  navigate(`/add-event`);
                }}
              >
                Book A Place
              </div>
            </div> */}

            {/* <FilterAndSearchBar
              selectedFilterType={selectedFilterType}
              setFilterType={setFilterType}
              dateValue={dateValue}
              eventsRefetch={eventsRefetch}
              setSearchEventName={setSearchEventName}
              searchEventName={searchEventName}
              handleClickDay={handleClickDay}
            /> */}

            {/* CALENDER & SCHDULEs */}
            <div className="xl:flex xl:flex-row xl:gap-6">
              {/* CALENDER */}
              <div className="w-full space-y-5">
                <Calendar
                  value={
                    selectedFilterType == EVENTS_FILTER_BY.DATE
                      ? dateValue
                        ? dateValue
                        : new Date()
                      : null
                  }
                  onClickDay={handleClickDay}
                  className="bg-gray-600"
                  onActiveStartDateChange={handleOnActiveStartDateChange}
                />

                {/* Subscription & single event INFO 👇 */}
                <SingleEventPromotion />
              </div>
              {/* SCHDULES */}

              <EventsList eventsData={eventsData?.findBaseEventsByFilter} findMyKids={findMyKids} />
            </div>
          </section>
        </div>
      </div>

      {/* MODAL MODAL */}
      {/* MODAL MODAL */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            backgroundColor: "#363b47f7",
            maxHeight: "80%",
            border: "none",
            paddingLeft: "30px",
            paddingRight: "30px",
          },
          overlay: {
            backgroundColor: "#00000033",
          },
        }}
        contentLabel="Example Modal"
      >
        {/* MODAL CLOSE BUTTON */}
        <button onClick={closeModal} style={{ float: "right" }}>
          <FontAwesomeIcon className="mt-1" icon={faClose} />
        </button>

        {/* MODAL TITLE */}
        <h1
          className="text-yellow-300 text-2xl mb-5"
          style={{ fontWeight: "600" }}
        >
          Assign to Members{" "}
          <FontAwesomeIcon
            className="mt-1"
            style={{ color: "white" }}
            icon={faUsers}
          />
        </h1>

        {/* SHOW KIDS */}
        {findMyKids &&
        findMyKids.findMyAllKidsToSubscribeBase &&
        findMyKids.findMyAllKidsToSubscribeBase.length > 0 ? (
          <div>
            {findMyKids.findMyAllKidsToSubscribeBase.map((kid, index) => {
              return (
                <div>
                  {/* KIDS */}
                  <div
                    className="bg-black/20 mt-4 flex items-center font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 py-2"
                    key={index}
                    style={{
                      borderLeft: "5px solid yellow",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* KID AVATAR + Name + Check button */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="py-2"
                    >
                      {/* KID AVATAR */}
                      <img
                        src={
                          RANDOM_AVATAR_URLS[kid.avatar]
                            ? RANDOM_AVATAR_URLS[kid.avatar]
                            : RANDOM_AVATAR_URLS["user4_asset4"]
                        }
                        alt="Basement Sports"
                        className="max-h-17"
                        style={{
                          color: "white",
                          width: "100px",
                          borderRight: "1px dotted #ffffff45",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}
                      />

                      {/* KID INFO section */}
                      <div className="font-semibold ml-5">
                        {/* CAPTION */}
                        <div>
                          Name: <b style={{ color: "#ccd0e0" }}>{kid.name}</b>
                        </div>
                        <div>
                          <b style={{ color: "#ccd0e0" }}>
                            {kid.gender == "male" ? "Boy" : "Girl"}
                          </b>
                        </div>
                        {kid.age && (
                          <div>
                            Age: <b style={{ color: "#ccd0e0" }}>{kid.age}</b>
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      className="mr-5"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {/* Check if KID not yet joined into the event */}
                      {!selectedEventDetails?.participantKidIds.includes(
                        kid._id
                      ) ? (
                        <>
                          {/* Check if the KID already a SUBSCRIBER for MONTH by parent */}
                          {kid.paymentDetails?.paymentFor ===
                          PAYMENT_FOR.PURCHASE_FULL_MONTH_EVENT_OF_BASE ? (
                            <button
                              onClick={() => {
                                joinEventAsASubscriptionUser(selectedEventId, [
                                  kid._id,
                                ]);
                              }}
                              className="text-xs font-bold py-1 px-5 rounded-full mr-3"
                              style={{
                                backgroundColor: "#0000008a",
                                color: "yellow",
                              }}
                            >
                              FREE TO JOIN
                            </button>
                          ) : (
                            <>
                              {/* If the KID not yet enrolled into the event && even not yet SUBSCRIBED for month */}
                              <div
                                onClick={() => {
                                  if (selectedKidIds.includes(kid._id)) {
                                    // Remove Array reference & create a new one
                                    let prevSelectedKidIds = [
                                      ...selectedKidIds,
                                    ];
                                    // Get KID ID index
                                    let index = prevSelectedKidIds.indexOf(
                                      kid._id
                                    );
                                    // SPLICE out the KID ID
                                    prevSelectedKidIds.splice(index, 1);
                                    // SET new ARRAY of IDs
                                    setSelectedKidIds(prevSelectedKidIds);
                                  } else {
                                    setSelectedKidIds([
                                      ...selectedKidIds,
                                      kid._id,
                                    ]);
                                  }
                                }}
                                className={`w-5 h-5 border-2 rounded-full border-yellow-300 mr-5 ${
                                  selectedKidIds.includes(kid._id)
                                    ? "bg-yellow-300"
                                    : null
                                }`}
                              ></div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {/* If the KID already joined into this event */}
                          <div
                            className="text-xs font-bold py-1 px-5 rounded-full mr-3"
                            style={{
                              backgroundColor: "#00000042",
                              color: "#ffff00bf",
                            }}
                          >
                            ENROLLED
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div>No Kids added yet! Please add kid first!</div>
        )}

        {/* FINAL SUBMIT BUTTON for payment/ Free for Join */}
        <div
          style={{ display: "flex", flexDirection: "column" }}
          className="mt-5"
        >
          {/* If selected STRIPE payment then show coupon code & Amount to pay Info. Othersie show available SLOTs */}
          {Number(parentUserAvailableSlot) > 0 ? (
            <div className="text-center">
              <div style={{ color: "yellow" }} className="text-xl">
                Total selected kids: {selectedKidIds.length}
              </div>
              <div style={{ color: "#add8ff" }} className="text-xl my-3">
                Slot available to use: {parentUserAvailableSlot}
              </div>
            </div>
          ) : (
            <div>
              {/* Coupon Code */}
              <div>
                <div className="mb-4">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <div
                      onClick={() => {
                        setShowCouponBox(!showCouponBox);
                        setCouponCode("");
                        setCouponCodeCheckResponse("");
                        setTotalDiscountInDollar(0);
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                      className="cursor-pointer"
                    >
                      <input
                        className="relative bg-yellow-300 rounded"
                        type="checkbox"
                        id="exampleCheck2"
                        checked={showCouponBox}
                      />
                      <div className="ml-2">Do you have any coupon code?</div>
                    </div>

                    {showCouponBox && (
                      <div>
                        <input
                          onChange={(e) => {
                            checkCouponValidity({
                              couponCode: e.target.value,
                              eventId: selectedEventDetails._id,
                              paymentFor:
                                PAYMENT_FOR.PURCHASE_SINGLE_EVENT_OF_BASE,
                            });
                          }}
                          className="bg-black/30 border-2  border-green-300  focus:border-yellow-300 focus:outline-none rounded-full h-8"
                          type="text"
                          placeholder="Coupon Code"
                        />
                        {couponCode && (
                          <p
                            className={`text-xs font-bold text-center ${
                              couponCodeCheckResponse === "Coupon found"
                                ? "text-green-400"
                                : "text-red-400"
                            }`}
                          >
                            {couponCodeCheckResponse}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Amount to pay */}
              <div
                className="text-center text-yellow-300 text-xl mb-4 mt-1"
                style={{ fontWeight: "700" }}
              >
                Total amount to pay: $
                {selectedEventPrice * selectedKidIds.length -
                  totalDiscountInDollar * selectedKidIds.length}{" "}
                {/* Discount */}
                {totalDiscountInDollar > 0 && (
                  <b
                    style={{ color: "white" }}
                    className="text-center text-sm mb-4"
                  >
                    (${totalDiscountInDollar * selectedKidIds.length} discount)
                  </b>
                )}
              </div>
            </div>
          )}

          {initiateStripeSessionMutationLoading ? (
            <button
              className="text-sm font-bold py-1 px-5 rounded-full max-h-7 mt-2"
              style={{
                backgroundColor: "#0000008a",
                color: "#FFFFFF",
              }}
            >
              Loading
            </button>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="text-sm font-bold py-3 px-6 rounded-lg  mt-2"
                style={{
                  backgroundColor: "#0078ffc7",
                  color: "#FFFFFF",
                  fontSize: "20px",
                }}
                onClick={() => {
                  initiateStripeCheckoutUi(
                    selectedEventId,
                    parentUserAvailableSlot
                  );
                }}
              >
                ENROLL KIDS
              </button>
            </div>
          )}
        </div>
      </Modal>

      {/* SPECIAL EVENT MODAL */}
      {/* SPECIAL EVENT MODAL */}
      <Modal
        isOpen={specialEventModalIsOpen}
        onRequestClose={closeSpecialEventModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            backgroundColor: "#363b47f7",
            maxHeight: "80%",
            border: "none",
            paddingLeft: "30px",
            paddingRight: "30px",
          },
          overlay: {
            backgroundColor: "#00000033",
          },
        }}
        contentLabel="Example Modal"
      >
        {/* MODAL CLOSE BUTTON */}
        <button onClick={closeSpecialEventModal} style={{ float: "right" }}>
          <FontAwesomeIcon className="mt-1" icon={faClose} />
        </button>

        {/* MODAL TITLE */}
        <h1
          className="text-yellow-300 text-2xl mb-5"
          style={{ fontWeight: "600" }}
        >
          Special Event{" "}
          <FontAwesomeIcon
            className="mt-1"
            style={{ color: "white" }}
            icon={faUsers}
          />
        </h1>

        {/* COUPON CODE ENTRY */}
        <div
          style={{ display: "flex", flexDirection: "column" }}
          className="mt-5"
        >
          <div>
            {/* Coupon Code */}
            <div>
              <div className="mb-4">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  {isBookFullSeason && (
                    <div
                      onClick={() => {
                        setShowCouponBox(!showCouponBox);
                        setCouponCode("");
                        setCouponCodeCheckResponse("");
                        setTotalDiscountInDollar(0);
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                      className="cursor-pointer"
                    >
                      <input
                        className="relative bg-yellow-300 rounded"
                        type="checkbox"
                        id="exampleCheck2"
                        checked={showCouponBox}
                      />
                      <div className="ml-2">Do you have any coupon code?</div>
                    </div>
                  )}

                  {showCouponBox && (
                    <div>
                      <input
                        onChange={(e) => {
                          // setCouponCode(e.target.value);
                          checkCouponValidity({
                            couponCode: e.target.value,
                            eventId: isBookFullSeason
                              ? selectedEventDetails?.hasRecurring == true
                                ? selectedEventId
                                : selectedEventDetails?.recurringFromEventId
                              : selectedEventId,
                            paymentFor:
                              PAYMENT_FOR.PURCHASE_SINGLE_EVENT_OF_BASE,
                          });
                        }}
                        className="bg-black/30 border-2  border-green-300  focus:border-yellow-300 focus:outline-none rounded-full h-8"
                        type="text"
                        placeholder="Coupon Code"
                      />
                      {couponCode && (
                        <p
                          className={`text-xs font-bold text-center ${
                            couponCodeCheckResponse === "Coupon found"
                              ? "text-green-400"
                              : "text-red-400"
                          }`}
                        >
                          {couponCodeCheckResponse}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* RECURRING EVENTS DETAILS at BOTTOM */}
            {isBookFullSeason && selectedEventDetails?.hasRecurring == true && (
              <SeasonDetails
                eventPrice={selectedEventDetails?.eventPrice}
                recurringStartDate={selectedEventDetails?.recurringStartDate}
                recurringEndDate={selectedEventDetails?.recurringEndDate}
                recurringDays={selectedEventDetails?.recurringDays}
                recurringEventIds={selectedEventDetails?.recurringEventIds}
                seasonPrice={selectedEventDetails?.seasonPrice}
                discount={selectedEventDetails?.discount}
              />
            )}

            {/* RECURRING FROM EVENT DETAILS at BOTTOM */}
            {isBookFullSeason &&
              selectedEventDetails?.recurringFromEventId &&
              selectedEventDetails.recurringFromEventId != "" && (
                <SeasonDetails
                  eventPrice={
                    selectedEventDetails?.recurringFromEventDetails?.eventPrice
                  }
                  recurringStartDate={
                    selectedEventDetails?.recurringFromEventDetails
                      ?.recurringStartDate
                  }
                  recurringEndDate={
                    selectedEventDetails?.recurringFromEventDetails
                      ?.recurringEndDate
                  }
                  recurringDays={
                    selectedEventDetails?.recurringFromEventDetails
                      ?.recurringDays
                  }
                  recurringEventIds={
                    selectedEventDetails?.recurringFromEventDetails
                      ?.recurringEventIds
                  }
                  seasonPrice={
                    selectedEventDetails?.recurringFromEventDetails?.seasonPrice
                  }
                  discount={selectedEventDetails?.discount}
                />
              )}

            {/* Amount to pay */}
            {isBookFullSeason ? (
              <div
                className="text-center text-yellow-300 text-xl mb-4 mt-1"
                style={{ fontWeight: "700" }}
              >
                Total amount to pay:{" "}
                {(totalDiscountInDollar > 0 ||
                  selectedEventDetails?.discount > 0) && (
                  <b className="text-slate-500 line-through font-normal">
                    $
                    {selectedEventDetails?.hasRecurring
                      ? selectedEventDetails?.seasonPrice +
                        selectedEventDetails?.discount
                      : selectedEventDetails?.recurringFromEventDetails
                          ?.seasonPrice +
                        selectedEventDetails?.recurringFromEventDetails
                          ?.discount}
                  </b>
                )}{" "}
                $
                {totalDiscountInDollar > 0 ? (
                  <b>
                    {(selectedEventDetails?.hasRecurring
                      ? selectedEventDetails?.seasonPrice +
                        selectedEventDetails?.discount
                      : selectedEventDetails?.recurringFromEventDetails
                          ?.seasonPrice +
                        selectedEventDetails?.recurringFromEventDetails
                          ?.discount) - totalDiscountInDollar}
                  </b>
                ) : (
                  <b>
                    {(selectedEventDetails?.hasRecurring
                      ? selectedEventDetails?.seasonPrice
                      : selectedEventDetails?.recurringFromEventDetails
                          ?.seasonPrice) - totalDiscountInDollar}
                  </b>
                )}{" "}
                {/* Discount */}
                {totalDiscountInDollar > 0 && (
                  <b className=" text-slate-300 text-center font-normal mb-4">
                    (${totalDiscountInDollar} discount)
                  </b>
                )}
              </div>
            ) : (
              <div
                className="text-center text-yellow-300 text-xl mb-4 mt-1"
                style={{ fontWeight: "700" }}
              >
                Total amount to pay: $
                {selectedEventPrice - totalDiscountInDollar} {/* Discount */}
                {totalDiscountInDollar > 0 && (
                  <b
                    style={{ color: "white" }}
                    className="text-center text-sm mb-4"
                  >
                    (${totalDiscountInDollar} discount)
                  </b>
                )}
              </div>
            )}
          </div>

          {initiateStripeSessionMutationLoading ? (
            <button
              className="text-sm font-bold py-1 px-5 rounded-full max-h-7 mt-2"
              style={{
                backgroundColor: "#0000008a",
                color: "#FFFFFF",
              }}
            >
              Loading
            </button>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="text-sm font-bold py-3 px-6 rounded-lg  mt-2"
                style={{
                  backgroundColor: "#0078ffc7",
                  color: "#FFFFFF",
                  fontSize: "20px",
                }}
                onClick={() => {
                  initiateStripeCheckoutUiForSpecialEvent();
                }}
              >
                {isBookFullSeason ? "BOOK FULL SEASON" : "PURCHASE EVENT"}
              </button>
            </div>
          )}
        </div>
      </Modal>
    </CommonBackdrop>
  );
};

export default EventsCalender;

const FilterAndSearchBar = ({
  selectedFilterType,
  setFilterType,
  dateValue,
  eventsRefetch,
  setSearchEventName,
  searchEventName,
  handleClickDay,
}) => {
  // Search event by name with debounce
  const searchEventByName = debounce(async (searchName) => {
    if (searchName && searchName !== "") {
      setFilterType(EVENTS_FILTER_BY.SEARCH_NAME);

      eventsRefetch({
        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
        searchName,
        filterBy: EVENTS_FILTER_BY.SEARCH_NAME,
        queryFromClient: true,
        userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
      });
    } else {
      setFilterType(EVENTS_FILTER_BY.DATE);

      eventsRefetch({
        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
        particularDate:
          !dateValue || dateValue === "" ? new Date() : new Date(dateValue),
        filterBy: EVENTS_FILTER_BY.DATE,
        queryFromClient: true,
        userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
      });
    }
  }, 400);

  const handleFilterByDate = () => {
    setFilterType(EVENTS_FILTER_BY.DATE);
    setSearchEventName("");

    if (!dateValue || dateValue === "") {
      const todayDate = new Date();
      eventsRefetch({
        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
        particularDate: todayDate,
        filterBy: EVENTS_FILTER_BY.DATE,
        queryFromClient: true,
        userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
      });
    } else {
      const selectedDateValue = new Date(dateValue);
      eventsRefetch({
        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
        particularDate: selectedDateValue,
        filterBy: EVENTS_FILTER_BY.DATE,
        queryFromClient: true,
        userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
      });
    }
  };

  const handleFilterByMonth = () => {
    setFilterType(EVENTS_FILTER_BY.MONTH);
    setSearchEventName("");

    let actionDate =
      !dateValue || dateValue === "" ? new Date() : new Date(dateValue);
    const year = actionDate.getFullYear();
    const month = actionDate.getMonth();

    eventsRefetch({
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      year,
      month,
      filterBy: EVENTS_FILTER_BY.MONTH,
      queryFromClient: true,
      userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
    });
  };

  const handleFilterByUpcoming = () => {
    setFilterType(EVENTS_FILTER_BY.UPCOMING);
    setSearchEventName("");

    eventsRefetch({
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      filterBy: EVENTS_FILTER_BY.UPCOMING,
      queryFromClient: true,
      userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
    });
  };

  const handleFilterByPast = () => {
    setFilterType(EVENTS_FILTER_BY.PAST);
    setSearchEventName("");

    eventsRefetch({
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      filterBy: EVENTS_FILTER_BY.PAST,
      queryFromClient: true,
      userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
    });
  };

  const handleFilterByRecurring = () => {
    let actionDate =
      !dateValue || dateValue === "" ? new Date() : new Date(dateValue);
    const year = actionDate.getFullYear();
    const month = actionDate.getMonth();

    eventsRefetch({
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      year,
      month,
      filterBy: EVENTS_FILTER_BY.RECURRING_EVENTS,
      queryFromClient: true,
      userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
    });

    setFilterType(EVENTS_FILTER_BY.RECURRING_EVENTS);
    setSearchEventName("");
  };

  const handleFilterByAll = () => {
    setFilterType(EVENTS_FILTER_BY.ALL);
    setSearchEventName("");

    eventsRefetch({
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      filterBy: EVENTS_FILTER_BY.ALL,
      queryFromClient: true,
      userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
    });
  };

  return (
    <div
      className="flex justify-between mb-3 px-5 mt-5 py-1 flex-wrap"
      style={{
        alignItems: "center",
        backgroundColor: "#00000033",
        borderRadius: "5px",
      }}
    >
      {/* FILTER */}
      <div className="mt-2">
        <div className="flex flex-wrap items-center justify-center">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mr-2 mb-2"
            onClick={handleFilterByDate}
            style={{
              opacity: selectedFilterType === EVENTS_FILTER_BY.DATE ? 1 : 0.4,
            }}
            data-tooltip-id="tooltip-content"
            data-tooltip-content="Filter events by date."
          >
            Filter by Date
          </button>

          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mr-2 mb-2"
            onClick={handleFilterByMonth}
            style={{
              opacity: selectedFilterType === EVENTS_FILTER_BY.MONTH ? 1 : 0.4,
            }}
            data-tooltip-id="tooltip-content"
            data-tooltip-content="Filter events by month!"
          >
            Filter by Month
          </button>

          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mr-2 mb-2"
            onClick={handleFilterByUpcoming}
            style={{
              opacity:
                selectedFilterType === EVENTS_FILTER_BY.UPCOMING ? 1 : 0.4,
            }}
            data-tooltip-id="tooltip-content"
            data-tooltip-content="Filter all upcoming events!"
          >
            Upcoming
          </button>

          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mr-2 mb-2"
            onClick={handleFilterByPast}
            style={{
              opacity: selectedFilterType === EVENTS_FILTER_BY.PAST ? 1 : 0.4,
            }}
            data-tooltip-id="tooltip-content"
            data-tooltip-content="Filter all past events!"
          >
            Past
          </button>

          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mr-2 mb-2"
            onClick={handleFilterByRecurring}
            style={{
              opacity:
                selectedFilterType === EVENTS_FILTER_BY.RECURRING_EVENTS
                  ? 1
                  : 0.4,
            }}
            data-tooltip-id="tooltip-content"
            data-tooltip-content="Filter events that have a recurring schedule"
          >
            Recurring
          </button>

          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mr-2 mb-2"
            onClick={handleFilterByAll}
            style={{
              opacity: selectedFilterType === EVENTS_FILTER_BY.ALL ? 1 : 0.4,
            }}
            data-tooltip-id="tooltip-content"
            data-tooltip-content="Filter all events!"
          >
            All
          </button>

          <div
            className="mb-2"
            data-tooltip-id="tooltip-content"
            data-tooltip-content="Reset filter"
          >
            <FontAwesomeIcon
              className="mt-1 cursor-pointer"
              icon={faRotateBack}
              onClick={() => handleClickDay(new Date())}
            />
          </div>
        </div>
      </div>

      {/* SEARCH */}
      <div>
        <input
          className="w-64 border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
          placeholder="Search events by name"
          onChange={(e) => {
            setSearchEventName(e.target.value);
            searchEventByName(e.target.value);
          }}
          value={searchEventName}
        />
      </div>
    </div>
  );
};

function AddEventButton() {
  const navigate = useNavigate();
  return (
    <div
      className="bg-yellow-800 px-2 rounded cursor-pointer"
      onClick={() => {
        if (
          !localStorage.getItem(
            LOCAL_STORAGE_KEYS.USER_ID // ✅ If Not Logged In
          )
        ) {
          navigate(`/login`);
          return;
        }

        navigate(`/add-event`);
      }}
    >
      Book A Place
    </div>
  );
}
