import { useCallback, useEffect, useState } from "react";
import { AUTH_TOKEN, LOCAL_STORAGE_KEYS } from "../../utils/constant";
import { useQuery } from "@apollo/client";
import FIND_ALL_RECORDINGS from "../query/base/findAllStreamsByFilter.query";
import FIND_BASE_DETAILS from "../query/base/findBaseDetailsForBaseDashboard.query";

// TODO get by base Id
export default function useGetRecordings() {
  const [recordingsLoaded, setRecordingsLoaded] = useState(false);
  const [recordings, setRecordings] = useState(null);

  const {
    data: baseData,
    loading: baseLoading,
    error: baseError,
    refetch: baseRefetch,
  } = useQuery(FIND_BASE_DETAILS, {
    variables: {
      baseId: LOCAL_STORAGE_KEYS.SUPER_BASE_ID,
      //baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  const {
    data: videoStreams,
    loading: loadingVideoStreams,
    error: errorVideoStreams,
    refetch: refetchVideoStreams,
  } = useQuery(FIND_ALL_RECORDINGS, {
    variables: {
      baseId: LOCAL_STORAGE_KEYS.SUPER_BASE_ID,
    },
  });

  const getAllRecordings = async (meetingId) => {
    const res = await fetch(
      `https://api.videosdk.live/v2/recordings/?roomId=${meetingId}`,
      {
        method: "GET",
        headers: {
          authorization: `${AUTH_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    return data;
  };

  /* const fetchRecordings = async () => {
    const recordingsData = await Promise.all(
      videoStreams?.findAllStreamsByFilter?.map(async (stream) => {
        const recordings = await getAllRecordings(stream.channelName);
        //console.log(recordings);

        if (recordings.pageInfo.total > 0) {          
          return recordings.data?.map((recording) => {
            //console.log("Recording created at", recording.createdAt);
            return {
              ...stream,
              baseDetails: baseData?.findBaseDetails,
              url: recording?.file?.fileUrl,
              streamStartedAt: recording?.createdAt,
            };
          });
        }
        return [];
      })
    );

    let flattenedRecordings = recordingsData.flat();
    flattenedRecordings.sort(
      (a, b) => new Date(b.streamStartedAt) - new Date(a.streamStartedAt)
    );

    setRecordings(flattenedRecordings);
    setRecordingsLoaded(true);
  }; */

  const fetchRecordings = useCallback(async () => {
    if (!videoStreams?.findAllStreamsByFilter || !baseData?.findBaseDetails) {
      return;
    }
    try {
      const recordingsData = await Promise.all(
        videoStreams.findAllStreamsByFilter.map(async (stream) => {
          try {
            const recordings = await getAllRecordings(stream.channelName);
            if (recordings?.pageInfo?.total > 0) {
              return recordings.data.map((recording) => ({
                ...stream,
                baseDetails: baseData.findBaseDetails,
                url: recording.file.fileUrl,
                streamStartedAt: recording.createdAt,
              }));
            }
            return [];
          } catch (error) {
            console.error(
              `Error fetching recordings for ${stream.channelName}:`,
              error
            );
            return [];
          }
        })
      );
      const flattenedRecordings = recordingsData
        .flat()
        .sort(
          (a, b) => new Date(b.streamStartedAt) - new Date(a.streamStartedAt)
        );
      setRecordings(flattenedRecordings);
      setRecordingsLoaded(true);
    } catch (error) {
      console.error("Error in fetchRecordings:", error);
      setRecordingsLoaded(true);
    }
  }, [videoStreams, baseData]);

  useEffect(() => {
    if (videoStreams?.findAllStreamsByFilter && baseData?.findBaseDetails) {
      fetchRecordings();
    } else {
      refetchVideoStreams();
      baseRefetch();
    }
  }, [videoStreams, baseData, fetchRecordings]);

  useEffect(() => {
    if (videoStreams?.findAllStreamsByFilter && baseData?.findBaseDetails) {
      if (recordingsLoaded) return;
      fetchRecordings();
    } else {
      refetchVideoStreams();
      baseRefetch();
    }
  }, [videoStreams, baseData]);

  //console.log(recordings);

  return {
    data: recordings ?? [],
    loading: loadingVideoStreams,
    error: errorVideoStreams, // TODO: include errors encountered fetching recordings
  };
}
