import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/free-solid-svg-icons";
import { formatDistanceToNow, formatDistanceToNowStrict } from "date-fns";
import { useNavigate } from "react-router-dom";
import iconFinder from "../../utils/iconFinder";

const FALLBACK_THUMBNAIL =
  "https://res.cloudinary.com/dvqldxdfv/image/upload/v1682695432/Screenshot_2023-04-28_at_9.09.42_srr2ch.png";

const VideoCard = ({
  video,
  width = "300px",
  height = "180px",
  baseName = "",
  avatarUrls,
  navigateTo = "",
  createdTime,
  fallbackThumbnail = "",
  fetchOtherVideos = "",
}) => {
  const navigate = useNavigate();

  const FALLBACK_NAVIGATION_TARGET = `/video/${video._id}${
    fetchOtherVideos ? `?otherVideoTypes=${fetchOtherVideos}` : ""
  }`;

  function navigateToVideo() {
    navigate(navigateTo ? navigateTo : FALLBACK_NAVIGATION_TARGET);
  }

  const getFormattedTime = () => {
    if (!createdTime) return "Recently"; // Handle undefined/null
    const date = new Date(createdTime);
    if (isNaN(date.getTime())) return "Recently"; // Invalid date
    return `${formatDistanceToNowStrict(date)} ago`;
  };

  return (
    <div className={`flex min-w-[${width}] justify-between`}>
      <button
        className="relative cursor-pointer rounded-lg overflow-hidden"
        onClick={navigateToVideo}
      >
        {/* Thumbnail */}
        <img
          src={
            video?.videoThumbnailUrl
              ? video.videoThumbnailUrl
              : fallbackThumbnail ?? FALLBACK_THUMBNAIL
          }
          alt={video?.name ?? "Base video"}
          className={`md:block object-cover w-full h-[${height}]`}
        />

        {/* Play Icon */}
        <div className="flex absolute items-center justify-center text-label-primary top-0 left-0 w-full h-[70%]">
          <FontAwesomeIcon icon={faCirclePlay} size="3x" />
        </div>

        {/* Video Details */}
        <div className="flex justify-between items-center bg-gray-600 px-2 py-2">
          <div className="flex flex-grow items-center">
            {/* Avatar */}
            {/* TODO: display actual user avatar instead of placeholder avatar */}
            <img
              src={
                avatarUrls[video?.userDetails?.avatar]
                  ? avatarUrls[video?.userDetails?.avatar]
                  : avatarUrls["user4_asset4"]
              }
              alt={video?.userDetails?.name ?? "User avatar"}
              className="w-[40px] h-[40px] mr-[8px]"
            />

            {/* Video Caption & Base Name */}
            <div className="flex flex-col items-start">
              <div className="w-full max-w-[130px] text-xs font-bold overflow-hidden whitespace-nowrap overflow-ellipsis">
                {video?.caption}
              </div>
              <div className="text-xs opacity-50">@{baseName ?? "base"}</div>
            </div>
          </div>

          <div className="flex flex-shrink w-fit text-xs opacity-50 whitespace-nowrap">
            {getFormattedTime()}
          </div>
        </div>
      </button>
    </div>
  );
};

export default VideoCard;
