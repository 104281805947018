import React from "react";

function HighlightCard({ video, onClick }) {
  return (
    <div className="w-[180px]">
      <button
        className="relative rounded-lg overflow-hidden mr-3 my-3 cursor-pointer"
        onClick={onClick}
      >
        <img
          src={
            video?.videoThumbnailUrl
              ? video.videoThumbnailUrl
              : "https://res.cloudinary.com/dvqldxdfv/image/upload/v1735731167/hr8opn3nlhpccuwqoh5b.png"
          }
          alt="Basement Sports"
          className="md:block min-w-[170px] object-cover h-[280px]"
        />
        {/* Overlay Text */}
        <div className="absolute inset-0 pt-[250px] bg-black bg-opacity-50 overflow-hidden justify-items-end whitespace-nowrap">
          <span className="text-white mt-auto">
            {video?.caption || "No Caption Available"}
          </span>
        </div>
      </button>
    </div>
  );
}

export default HighlightCard;

// "https://res.cloudinary.com/dvqldxdfv/image/upload/v1682695432/Screenshot_2023-04-28_at_9.09.42_srr2ch.png"