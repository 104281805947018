import { gql } from "@apollo/client";

export default gql`
  query findAllStreamsByFilter(
    $baseSlug: String
    $baseId: String!
    $fetchLiveOnly: Boolean
  ) {
    findAllStreamsByFilter(
      baseSlug: $baseSlug
      baseId: $baseId
      fetchLiveOnly: $fetchLiveOnly
    ) {
      _id
      userId
      userDetails {
        _id
        name
        avatar
      }
      appId
      channelName
      gameName
      baseDetails {
        _id
        name
        logo
        city
        slug
        country
      }
      tournamentStatus
      teamIds
      videoThumbnailUrl
      caption
      createdAt
      updatedAt
    }
  }
`;
