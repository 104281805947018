import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faDatabase,
  faCalendarDay,
  faUserClock,
  faBucket,
  faSignOutAlt,
  faArrowLeft,
  faArrowRight,
  faBasketShopping,
  faComment,
  faEnvelope,
  faQrcode,
  faShoppingCart,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { LOCAL_STORAGE_KEYS, RANDOM_AVATAR_URLS } from "../../utils/constant";
import { AvatarIcon, AvatarIcons } from "./AvatarIcons";

// Navigation Configuration
const ROUTES = {
  dashboard: {
    name: "Dashboard",
    path: "/dashboard",
    icon: faBars,
  },
  bases: {
    name: "Bases",
    path: "/", // Default route for Bases
    icon: faDatabase,
    children: [
      { name: "Super Base", path: "/" },
      { name: "Manage Membership", path: "/manage-kids", requiresAuth: true },
      { name: "Add Members", path: "/add-kid", requiresAuth: true },
      { name: "Book Sessions", path: "/base/eventscalender" },
      { name: "Video Highlights", path: "/highlights" },
      { name: "Live Streams", path: "/streams" },
    ],
  },
  schedule: {
    name: "Schedule",
    path: "/base/eventscalender", // Default route for Schedule
    icon: faCalendarDay,
    children: [
      { name: "Events Calendar", path: "/base/eventscalender" },
      { name: "My Events", path: "/base/my-events", requiresAuth: true },
    ],
  },
  account: {
    name: "Account",
    path: "/profile", // Default route for Account
    icon: faUserClock,
    requiresAuth: true,
    children: [
      { name: "Profile", path: "/profile" },
      { name: "Payment History", path: "/payment-history" },
    ],
  },
  marketplace: {
    name: "Marketplace",
    path: "/my-nfts", // Default route for Marketplace
    icon: faBucket,
    children: [{ name: "MY NFTs", path: "/my-nfts", requiresAuth: true }],
  },
};

// Helper Components
const Logo = ({ showSideBar }) => (
  <>
    {showSideBar ? (
      <img
        src="/bsports-logo-new.png"
        alt="Basement Sports"
        className="md:block w-3/5 mt-6 mb-6 object-contain"
      />
    ) : (
      <img
        src="https://res.cloudinary.com/dva2poi3c/image/upload/v1694406557/bSportsVLogo_pel9sf.png"
        alt="Basement Sports"
        className="md:block w-7 mr-1 mt-6 mb-6 object-contain"
      />
    )}
  </>
);

const SidebarLink = ({ link, showLabel = true }) => {
  const isAuthenticated = !!localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID);
  const navigate = useNavigate();

  if (link.requiresAuth && !isAuthenticated) return null;

  const handleClick = () => {
    if (link.requiresAuth && !isAuthenticated) {
      navigate("/login");
      return;
    }
    navigate(link.path);
  };

  return (
    <div className="text-white text-[13px] cursor-pointer hover:text-label-primary">
      <button onClick={handleClick}>{showLabel && link.name}</button>
    </div>
  );
};

const SidebarCategory = ({ category, isActive, onClick, showLabel = true }) => {
  const isAuthenticated = !!localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID);
  const navigate = useNavigate();

  if (category.requiresAuth && !isAuthenticated) return null;

  const handleCategoryClick = () => {
    onClick();
    if (category.requiresAuth && !isAuthenticated) {
      navigate("/login");
      return;
    }
    // Navigate to the category's default path if available
    if (category.path) {
      navigate(category.path);
    }
  };

  return (
    <button
      onClick={handleCategoryClick}
      className={`flex mt-3 cursor-pointer ${
        isActive ? "text-label-primary" : ""
      }`}
    >
      <FontAwesomeIcon icon={category.icon} className="mt-1" />
      {showLabel && <h1 className="ml-1 mt-0.5 font-bold">{category.name}</h1>}
    </button>
  );
};

const CategoryLinks = ({ category, showLabels = true }) => {
  if (!category.children) return null;

  return (
    <div className="ml-5 font-display flex flex-col gap-2 py-2">
      {category.children.map((link, index) => (
        <SidebarLink
          key={`${link.name}-${index}`}
          link={link}
          showLabel={showLabels}
        />
      ))}
    </div>
  );
};

const Navigation = ({ currentTab, setCurrentTab, showLabels = true }) => {
  return (
    <div className="flex flex-col">
      {Object.entries(ROUTES).map(([key, category]) => (
        <div key={key}>
          <SidebarCategory
            category={category}
            isActive={currentTab === category.name}
            onClick={() => setCurrentTab(category.name)}
            showLabel={showLabels}
          />
          <CategoryLinks category={category} showLabels={showLabels} />
        </div>
      ))}
    </div>
  );
};

const SidebarFooter = ({ showSideBar }) => (
  <div className="flex flex-row justify-between items-center">
    <AvatarIcon avatar={RANDOM_AVATAR_URLS.user1_asset1} />

    <span className="flex flex-row justify-evenly ml-5 flex-grow">
      <FontAwesomeIcon icon={faComment} />
      <FontAwesomeIcon icon={faEnvelope} />
      <FontAwesomeIcon icon={faQrcode} />
      <FontAwesomeIcon icon={faShoppingCart} />
    </span>
  </div>
);

const AuthSection = () => {
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID);

  const handleLogout = () => {
    localStorage.removeItem("userid");
    localStorage.removeItem("bsports_user_name");
    localStorage.removeItem("user_email");
    localStorage.removeItem("kidcointoken");
    localStorage.removeItem("affiliateCouponCode");
    localStorage.removeItem(LOCAL_STORAGE_KEYS.SUPER_BASE_ID);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_TYPE);
    navigate("/");
  };

  return (
    <div
      onClick={isAuthenticated ? handleLogout : () => navigate("/login")}
      className="flex cursor-pointer mt-auto"
    >
      <FontAwesomeIcon icon={faSignOutAlt} className="mt-1" />
      <h1 className="ml-1 text-white font-medium">
        {isAuthenticated ? "Log out" : "Log In"}
      </h1>
    </div>
  );
};

const Sidebar = ({ showSideBar, currentTab, setCurrentTab }) => {
  const Logo = () => (
    <img
      src="/bsports-logo-new.png"
      alt="Basement Sports"
      className="md:block w-3/5 mt-6 mb-6 object-contain"
    />
  );

  return (
    <aside
      className={`
        items-start pb-10 pt-6 sm:pt-8 md:pt-50 
        bg-surface-secondary md:mr-1 
        hidden md:block
        min-w-[300px] max-w-[550px]
      `}
    >
      <div
        className={`
          flex flex-col justify-start
          fixed h-full   
          scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-transparent 
          px-4 md:px-6 lg:px-8
          pb-3 sm:pb-4 md:pb-5
        `}
      >
        <div className="flex-shrink-0">
          <Logo showSideBar={showSideBar} />
        </div>

        <div className="flex-grow h-fit sm:max-h-[200px] md:max-h-[800px] overflow-y-auto">
          <Navigation
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            showLabels={showSideBar}
          />
        </div>

        <div className="flex flex-col flex-shrink gap-3 mt-auto pb-10">
          <AuthSection />
          {/* <SidebarFooter /> */}
        </div>
      </div>
    </aside>
  );
};

const CollapsedSidebar = ({ currentTab, setCurrentTab }) => {
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID);

  const handleLogout = () => {
    localStorage.removeItem("userid");
    localStorage.removeItem("bsports_user_name");
    localStorage.removeItem("user_email");
    localStorage.removeItem("kidcointoken");
    localStorage.removeItem("affiliateCouponCode");
    localStorage.removeItem(LOCAL_STORAGE_KEYS.SUPER_BASE_ID);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_TYPE);
    navigate("/");
  };

  return (
    <aside className="items-start pt-6 sm:pt-8 md:pt-10 bg-surface-secondary md:mr-1 hidden md:flex min-w-[64px]">
      <div className="flex flex-col justify-between fixed scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-transparent px-2 sm:px-4 pb-3 sm:pb-4 md:pb-5 w-16">
        <div className="flex-shrink-0">
          <img
            src="https://res.cloudinary.com/dva2poi3c/image/upload/v1694406557/bSportsVLogo_pel9sf.png"
            alt="Basement Sports"
            className="md:block w-7 mr-1 mt-6 mb-6 object-contain"
          />
        </div>

        <div className="flex-grow pl-2 overflow-y-auto">
          <div className="flex flex-col mx-auto">
            {Object.entries(ROUTES).map(([key, category]) => (
              <SidebarCategory
                key={key}
                category={category}
                showLabel={false}
                isActive={currentTab === category.name}
                onClick={() => setCurrentTab(category.name)}
              />
            ))}
          </div>
        </div>

        <div className="flex-shrink-0 mt-auto">
          <button
            onClick={isAuthenticated ? handleLogout : () => navigate("/login")}
            className="flex mt-3 cursor-pointer"
          >
            <FontAwesomeIcon icon={faSignOutAlt} className="pl-2 mt-1" />
          </button>

          {/* <div className="flex flex-row mb-10 justify-center items-center">
            <img
              src="/cake_base_deepskyblue.png"
              className="flex-shrink w-[40px] h-[40px] object-fit"
              alt="Base Logo"
            />
          </div> */}
        </div>
      </div>
    </aside>
  );
};

// Mobile Sidebar Component
const MobileSidebar = ({
  showSideBar,
  setShowSideBar,
  currentTab,
  setCurrentTab,
}) => {
  return (
    <div
      className={`z-50 rounded w-11/12 bg-black/90 opacity-90 pb-[20px] px-5 flex flex-col md:hidden absolute duration-500 sm:duration-700 ${
        showSideBar ? "translate-x-[0%]" : "-translate-x-[200%]"
      }`}
    >
      <div className="w-full flex justify-between items-center">
        <Logo showSideBar={showSideBar} />
        <FontAwesomeIcon
          onClick={() => setShowSideBar(false)}
          className="cursor-pointer"
          icon={faArrowLeft}
        />
      </div>

      <Navigation
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        showLabels={true}
      />

      <AuthSection showLabels={true} />
    </div>
  );
};

// Main Layout Component
export default function CommonBackdrop({
  children,
  rootName,
  pageName,
  Button,
  ...props
}) {
  const [currentTab, setCurrentTab] = useState("Home");
  const [showSideBar, setShowSideBar] = useState(true);
  const navigate = useNavigate();

  const currentUrl = window.location.pathname;
  const hideBar = ["/base/registration"];
  const pathContainsEventDetails = currentUrl.includes("/event-details");

  function hideSideBar() {
    if (hideBar.includes(currentUrl)) return true;
    return (
      pathContainsEventDetails &&
      !localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)
    );
  }

  const bgImage =
    "https://res.cloudinary.com/dva2poi3c/image/upload/v1694410382/bSportsbackground_oezmdo.png";
  const style = {
    backgroundRepeat: "repeat",
    backgroundImage: `url(${bgImage})`,
  };

  // TODO: styling related to width causes the layout to break, when we have a horizontally scrolling child
  return (
    <div
      className={`overflow-y-auto md:overflow-hidden bg-cover bg-center h-screen`}
      style={style}
    >
      <div className="md:flex flex-row h-full">
        {!hideSideBar() && (
          <>
            {showSideBar ? (
              <Sidebar currentTab={currentTab} setCurrentTab={setCurrentTab} />
            ) : (
              <CollapsedSidebar
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
              />
            )}
            <MobileSidebar
              showSideBar={showSideBar}
              setShowSideBar={setShowSideBar}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
          </>
        )}

        <main className="flex flex-col w-full">
          <div className="flex justify-between px-10 mt-6 ml-4">
            <div className="flex w-full justify-between items-center">
              <span className="flex w-fit items-center">
                <FontAwesomeIcon
                  onClick={() => setShowSideBar(!showSideBar)}
                  className="cursor-pointer text-white"
                  icon={showSideBar ? faArrowLeft : faArrowRight}
                />
                <div className="h-4 w-[0.5px] border-2 border-white mx-1" />
                <h1 className="font-medium text-secondary ml-3">
                  {currentTab}
                </h1>
              </span>

              {/* <span className="w-fit">
                <AvatarIcons
                  avatars={Object.values(RANDOM_AVATAR_URLS).slice(0, 3)}
                />
              </span> */}
            </div>
          </div>

          {/* Bread Crumb */}
          <div className="flex flex-row justify-between bg-surface-secondary py-4 mx-12 mt-5 text-label-primary px-6 rounded-lg">
            <span>
              {rootName} {pageName ? ` / ${pageName}` : ""}
            </span>
            {Button ? (
              <span>
                <Button />
              </span>
            ) : null}
          </div>

          <div className="mx-12 mt-5 overflow-y-auto pb-10">
            {children}
          </div>
        </main>

        {/* <div className="justify-center hidden md:flex pt-16 h-full w-[65px] rounded-l-md bg-green">
          <FontAwesomeIcon icon={faSearch} />
        </div> */}
      </div>
    </div>
  );
}
